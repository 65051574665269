import React from "react";
import { Layout } from "components/general/Layout";
import { MainLayout } from "components/general/MainLayout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "pages/authentication/LoginPage";
import { useResource } from "hooks/useResource";
import { SignUpPage } from "pages/authentication/SignUpPage";


export const RoutesProvider: React.FC = () => {
  const { currentRoute } = useResource();
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage currentRoute={currentRoute} mainObject="login" />}
        />
                <Route
          path="/signup"
          element={<SignUpPage currentRoute={currentRoute} mainObject="signup" />}
        />

        <Route path="/" element={<Layout />}>
          <Route index element={<MainLayout />} />
          <Route path="*" element={<MainLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
