import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";

import { CalendarMonth, DateRange } from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event";
import { OrderDto } from "dto/sale/order.dto";

type Props = {
    object: OrderDto;
  };

const ColumnDate: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("date")} placement="right">
          <CalendarMonth />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "date_name"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("datedelivery")} placement="right">
          <DateRange />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "datedelivery_name"])
            ? CommonTools.processObjectField(object, [
                
                "datedelivery_name",
              ])
            : "-"}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("datepayment")} placement="right">
          <EventIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "datepayment_name"])
            ? CommonTools.processObjectField(object, [
                
                "datepayment_name",
              ])
            : "-"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { ColumnDate };
