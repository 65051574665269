import React from "react";
import { VideoService } from "services/system/video.service";

import { IPage } from "interfaces/page.interface";

import { VideoDto } from "dto/system/video.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormVideo } from "components/special/video/FormVideo";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";
import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new VideoService();
const SpecialVideoAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: VideoDto) => {
    if (!obj) return;
    obj.id_parent = parseInt(parentId.toString());
    obj.parent = parentType;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "video",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="video"
      />
      <DataDisplayBox>
        <FormVideo
          defaultObject={
            new VideoDto(parseInt(idSelectedLanguage.toString()))
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialVideoAdd };
