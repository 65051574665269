import React from "react";
import { MyButton, MyButtonProps } from "./MyButton";
import { Box, Stack } from "@mui/material";
import { CircularLoading } from "../loading/CircularLoading";

type Props = MyButtonProps & {
  loading: boolean;
};

const ButtonWithLoading: React.FC<Props> = ({
  loading,
  children,
  ...props
}) => {
  return (
    <MyButton
      {...props}
      fullWidth
      sx={{
        maxWidth: 400,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"center"}
      >
        {loading && <CircularLoading size={15} />}
        <Box>{children}</Box>
      </Stack>
    </MyButton>
  );
};

export { ButtonWithLoading };
