import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { AutocompleteCountry } from "components/static/country/AutocompleteCountry";

import { DeliveryMethodLocationDto } from "dto/delivery/deliverymethodlocation.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";
import { SelectDeliveryMethod } from "../deliverymethod/SelectDeliveryMethod";
import { AutocompleteLocation } from "components/static/location/AutocompleteLocation";

type Props = {
  defaultObject: DeliveryMethodLocationDto;
  onSubmit: (data: DeliveryMethodLocationDto) => void;
  loading: boolean;
};
const FormDeliveryMethodLocation: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,setObject] = useForm<DeliveryMethodLocationDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "idcountry",
      "iddeliverymethod",
      "idlocation",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  const handleSetCountry = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["idlocation"] = "";

    setObject(t);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectDeliveryMethod
            setObjectField={setObjField}
            value={obj.iddeliverymethod}
          />
        </Box>
        <Box mt={3}>
          <AutocompleteCountry
            setObjField={handleSetCountry}
            defaultObject={obj.countryObj}
            value={obj.idcountry}
          />
        </Box>
        {obj.idcountry ? (
          <Box mt={3}>
            <AutocompleteLocation
              setObjField={setObjField}
              defaultObject={obj.locationObj}
              idcountry={obj.idcountry}
              value={obj.idlocation}
            />
          </Box>
        ) : null}
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormDeliveryMethodLocation };
