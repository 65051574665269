import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";

import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";
import { PutOrderDto } from "dto/sale/order.dto";

type Props = {
  defaultObject: PutOrderDto;
  onSubmit: (data: PutOrderDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormOrder: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField] = useForm<PutOrderDto>(
    defaultObject,
    RequiredValidator.getValidators([])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="coments"
            label={LL("field_coments")}
            setObjectField={setObjField}
            value={obj.coments}
            multiline
            minRows={3}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="delivery_comments"
            label={LL("field_delivery_comments")}
            setObjectField={setObjField}
            value={obj.delivery_comments}
            multiline
            minRows={3}
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormOrder };
