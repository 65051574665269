import { Status } from "tools/types/status";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";

export class VideoDto {
  id?: number;
  parent?: string;
  id_parent?: number;
  order?: number;
  isdefault?: number;
  status?: number;
  _idlang?: number;
  _name?: string;
  video_type?: number;
  video_location?: string;
  video_id?: string;
  video_title?: string;
  video_image_default?: string;
  video_image_medium?: string;
  video_image_high?: string;
  video_image_standard?: string;
  video_image_maxres?: string;
  constructor(
    _idlang: number,
    parent?: string,
    id_parent?: number,
    id?: number,
    order?: number,
    isdefault?: number,
    status?: number,
    _name?: string,
    video_type?: number,
    video_location?: string,
    video_id?: string,
    video_title?: string,
    video_image_default?: string,
    video_image_medium?: string,
    video_image_high?: string,
    video_image_standard?: string,
    video_image_maxres?: string
  ) {
    this._idlang = _idlang;
    this.id = id ?? 0;
    this.parent = parent ?? "";
    this.id_parent = id_parent ?? 0;
    this.order = order ?? 0;
    this.isdefault = isdefault ?? 0;
    this.status = status ?? Status.ACTIVE;
    this._name = _name ?? "";
    this.video_type = video_type ?? 0;
    this.video_location = video_location ?? "";
    this.video_id = video_id ?? "";
    this.video_title = video_title ?? "";
    this.video_image_default = video_image_default ?? "";
    this.video_image_medium = video_image_medium ?? "";
    this.video_image_high = video_image_high ?? "";
    this.video_image_standard = video_image_standard ?? "";
    this.video_image_maxres = video_image_maxres ?? "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "isdefault",
        value: ["isdefault_name"],
      },
      {
        typeField: Types.FIELD_TYPE_VIDEO,
        label: "video",
        value: ["video_id"],
        options: {
          fields: ["video_type"],
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "isdefault"),

      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_StandardColumn(LL, "video_location"),
      ComponentCommonTools.columns_StandardColumn(LL, "video_title"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(
        LL,
        "isdefault",
        undefined,
        true,
        true,
        "boolean"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "video"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
