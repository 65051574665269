import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { VideoDto } from "dto/system/video.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: VideoDto;
  onSubmit: (data: VideoDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormVideo: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();
  const validators = forAdd
    ? RequiredValidator.getValidators(["video_location"])
    : [];
  const [obj, disabled, setObjField, , setLanguage,,vResults] = useForm<VideoDto>(
    defaultObject,
    validators
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>

        <Box mt={3}>
          <MyCheckboxField
            field="isdefault"
            label={LL("field_isdefault")}
            setObjectField={setObjField}
            checked={CommonTools.processNumberToBoolean(obj.isdefault)}
            isNumber
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <MyTextField
              field="video_location"
              label={LL("field_video_location")}
              setObjectField={setObjField}
              value={obj.video_location}
              vResults={vResults}
            />
          </Box>
        )}
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormVideo };
