import React from "react";
import {
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { GeneralService } from "services/general.service";

type ConfirmDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  titleDialog: string;
  contentDialog: string;
  setTitleDialog: any;
  setContentDialog: any;
  handleConfirm: () => void;
  handleCancel: () => void;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  setOpen,
  titleDialog,
  contentDialog,
  setTitleDialog,
  setContentDialog,
  handleConfirm,
  handleCancel,
  ...props
}) => {
  
  const handleClose = () => {
    if (handleCancel === undefined) {
      setOpen(false);
      setTitleDialog("");
      setContentDialog("");
      return;
    }
    handleCancel();
    setTitleDialog("");
    setContentDialog("");
    setOpen(false);
  };

  const confirmAction = () => {
    if (handleConfirm === undefined) {
      setTitleDialog("");
      setContentDialog("");
      setOpen(false);
      return;
    }
    handleConfirm();
    setTitleDialog("");
    setContentDialog("");
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title-confirm"
      aria-describedby="alert-dialog-description-confirm"
    >
      <DialogTitle id="alert-dialog-title-confirm">{titleDialog}</DialogTitle>
      <DialogContent>
        <Typography>{contentDialog}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmAction} color="secondary">
          {GeneralService.LL("Confirm")}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {GeneralService.LL("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
