import React from "react";

import { IPage } from "interfaces/page.interface";
import { GalleryService } from "services/system/gallery.service";
import { GalleryDto } from "dto/system/gallery.dto";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FormGallery } from "components/special/gallery/FormGallery";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Grid } from "@mui/material";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";
import { useResource } from "hooks/useResource";
import { DetailGrid } from "components/elements/detail/DetailGrid";

const service = new GalleryService();
const SpecialGalleryEdit: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    parentId,
    parentType,
    getByLanguage,
  } = useEditSpecial<GalleryDto>(
    "gallery",
    mainObject,
    currentRoute,
    get,
    true
  );

  const onSubmit = (obj: GalleryDto) => {
    if (!obj) return;
    setLoading(true);
    obj._idlang = parseInt(idLanguage.toString());
    obj.id_parent = parseInt(parentId.toString());
    obj.parent = parentType;

    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEditSpecial;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          parentType,
          parentId,
          specialPart: "gallery",
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
        };
    service.updateMultipart(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
        parentId={parentId}
        parentType={parentType}
        specialType="gallery"
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormGallery
            defaultObject={GalleryDto.parseForEdit(object)}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
        <Box mt={3}>
          <Grid>
            <DetailGrid
              object={object}
              fields={CommonTools.parseDetailFields(
                ["image"],
                GalleryDto.getDetailFields()
              )}
            />
          </Grid>
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialGalleryEdit };
