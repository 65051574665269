import { ChangePasswordDto, LoginSiteDto, SignupDto, UserDto } from "dto/user/user.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { UserContext } from "providers/UserProvider";
import { useContext } from "react";

type ResponseType = {
  signUp: (data: SignupDto, cb?: CallbackType) => void;
  user: UserDto | null;
  logout: () => void;
  login: (data: LoginSiteDto, cb?: CallbackType) => void;
  processUser: (obj: UserDto) => void;
  changeProfile: (data: UserDto, cb?: CallbackType) => void;
  changePassword: (data: ChangePasswordDto, cb?: CallbackType) => void;
};
export const useUser = (): ResponseType => {
  const {
    signUp,
    user,
    logout,
    login,
    processUser,
    changeProfile,
    changePassword,
  } = useContext(UserContext);

  return {
    signUp,
    user,
    logout,
    login,
    processUser,
    changeProfile,
    changePassword,
  };
};
