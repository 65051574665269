import SelectOptions from "dto/app/selectoptions.dto";

export class FAQType {
  public static _LL: any = false;
  public static setLL(_l: any): any {
    this._LL = _l;
  }

  public static HOME_PAGE = 1;
  public static GENERAL = 2;

  public static _constants: { [key: number]: string } = {
    1: "HOME_PAGE",
    2: "GENERAL",
  };

  // TODO: Implement this
  public static LL = (key: any) => {
    const k: number = parseInt(key);
    if (!this._constants.hasOwnProperty(k)) return "";
    return this._LL("FAQType_" + this._constants[k].toString());
  };

  public static GA_KEYS = (key: string): Array<number> => {
    let rez: Array<number> = [];

    if (key === "general") {
      rez = [this.HOME_PAGE, this.GENERAL];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyValue?: boolean,
    emptyLabel?: string
  ) => {
    const keys = this.GA_KEYS(key);
    const rez: Array<SelectOptions> = [];
    if (emptyValue) {
      const empty = new SelectOptions();
      empty.value = "";
      empty.label = emptyLabel ?? FAQType._LL("all");
      rez.push(empty);
    }
    keys.forEach((k) => {
      const so = new SelectOptions();
      so.value = k;
      so.label = FAQType.LL(k);
      rez.push(so);
    });

    return rez;
  };
}
