import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyCKEditor } from "components/elements/form/MyCKEditor";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: CategoryProductDto;
  onSubmit: (data: CategoryProductDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormCategoryProduct: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setLanguage,,vResults] =
    useForm<CategoryProductDto>(
      defaultObject,
      RequiredValidator.getValidators(["_name"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_title"
            label={LL("field_title")}
            setObjectField={setObjField}
            value={obj._title}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_slug"
            label={LL("field_slug")}
            setObjectField={setObjField}
            value={obj._slug}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_metakey"
            label={LL("field_metakey")}
            setObjectField={setObjField}
            value={obj._metakey}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_metadescription"
            label={LL("field_metadescription")}
            setObjectField={setObjField}
            value={obj._metadescription}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            field="showonhome"
            label={LL("field_shwoonhome")}
            setObjectField={setObjField}
            checked={CommonTools.processNumberToBoolean(obj.showonhome)}
            isNumber
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_shortdescription"
            label={LL("field_shortdescription")}
            setObjectField={setObjField}
            value={obj._shortdescription}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditor
            field="_description"
            setObjectField={setObjField}
            data={obj._description}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormCategoryProduct };
