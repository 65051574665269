import React from "react";

import { IPage } from "interfaces/page.interface";
import { OrderService } from "services/sale/order.service";
import { OrderDto } from "dto/sale/order.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { FormOrder } from "components/sale/order/FormOrder";

const service = new OrderService();
const EditOrder: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id } =
    useEditObject<OrderDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: OrderDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
      />

      <DataDisplayBox>
        <FormOrder
          defaultObject={OrderDto.toEditDto(object)}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditOrder };
