import { MyButton } from "components/elements/button/MyButton";
import { useLanguage } from "providers/LanguageProvider";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  open: boolean;
  toggleDetail: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const SelectedLanguageButton: React.FC<Props> = ({ open, toggleDetail }) => {
  const { getCurrentLanguage, languageSiteObjects } = useLanguage();
  const obj = getCurrentLanguage();

  const isDisabled = languageSiteObjects.length === 1;
  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) return;
    if (event) toggleDetail(event);
  };
  if (!obj) return null;
  return (
    <MyButton
      disabled={isDisabled}
      id="basic-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup={true}
      aria-expanded={open ? true : undefined}
      cb={handleClick}
    >
      {CommonTools.processObjectField(obj, ["nativename"])}
    </MyButton>
  );
};

export { SelectedLanguageButton };
