import React, { useState } from "react";
import {CountryService} from "services/static/country.service";

import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { CountryDto } from "dto/static/country.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormCountry } from "components/static/country/FormCountry";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

const service = new CountryService();
const AddCountry: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (obj: CountryDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
      <FormCountry defaultObject={new CountryDto()} onSubmit={onSubmit} loading={loading} />
      </DataDisplayBox> 
    </React.Fragment>
  );
};

export { AddCountry };
