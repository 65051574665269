import ResultObjectDTO from "dto/app/resultobject.dto";
import { OrderDto, SetActionDto } from "dto/sale/order.dto";
import { useMessage } from "providers/MessageProvider";
import { OrderService } from "services/sale/order.service";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "./useLabel";

type Props = (
  object: OrderDto,
  callbackFunction: () => void
) => {
  handleSetNewStatus: () => void;
  handleSetStatusVerified: () => void;
  handleSetStatusInProcess: () => void;
  handleSetStatusProcessed: () => void;
  handleSetStatusOnCourier: () => void;
  handleSetStatusDelivered: () => void;
  handleSetStatusArchived: () => void;
  handleSetStatusCancelled: () => void;
  handleSetStatusReturned: () => void;
  handleSetPayStatusPaid: () => void;
  handleSetPayStatusNeedReturn: () => void;
  handleSetPayStatusCanceled: () => void;
  handleSetPayStatusReturned: () => void;
};

const service = new OrderService();

export const useOrderActions: Props = (object, callbackFunction) => {
  const { LL } = useLabel();
  const { registerDialog } = useMessage();
  const postObject = new SetActionDto(
    CommonTools.processObjectField(object, ["uuid"])
  );
  const setStatusNew = () => {
    service.setStatusNew(cb, {}, postObject);
  };
  const setStatusVerified = () => {
    service.setStatusVerified(cb, {}, postObject);
  };
  const setStatusInProcess = () => {
    service.setStatusInProcess(cb, {}, postObject);
  };
  const setStatusProcessed = () => {
    service.setStatusProcessed(cb, {}, postObject);
  };
  const setStatusOnCourier = () => {
    service.setStatusOnCourier(cb, {}, postObject);
  };
  const setStatusDelivered = () => {
    service.setStatusDelivered(cb, {}, postObject);
  };
  const setStatusArchived = () => {
    service.setStatusArchived(cb, {}, postObject);
  };
  const setStatusCancelled = () => {
    service.setStatusCancelled(cb, {}, postObject);
  };
  const setStatusReturned = () => {
    service.setStatusReturn(cb, {}, postObject);
  };
  const setPayStatusPaid = () => {
    service.setPayStatusPaid(cb, {}, postObject);
  };
  const setPayStatusNeedReturn = () => {
    service.setPayStatusNeedReturn(cb, {}, postObject);
  };
  const setPayStatusCanceled = () => {
    service.setPayStatusCanceled(cb, {}, postObject);
  };
  const setPayStatusReturned = () => {
    service.setPayStatusReturned(cb, {}, postObject);
  };

  const handleSetNewStatus = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_new_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusNew,
      },
      null
    );
  };

  const handleSetStatusVerified = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_verified_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusVerified,
      },
      null
    );
  };

  const handleSetStatusInProcess = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_in_process_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusInProcess,
      },
      null
    );
  };

  const handleSetStatusProcessed = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_processed_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusProcessed,
      },
      null
    );
  };

  const handleSetStatusOnCourier = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_on_courier_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusOnCourier,
      },
      null
    );
  };

  const handleSetStatusDelivered = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_delivered_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusDelivered,
      },
      null
    );
  };

  const handleSetStatusArchived = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_archived_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusArchived,
      },
      null
    );
  };

  const handleSetStatusCancelled = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_cancelled_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusCancelled,
      },
      null
    );
  };

  const handleSetStatusReturned = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_returned_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setStatusReturned,
      },
      null
    );
  };

  const handleSetPayStatusPaid = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_paid_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setPayStatusPaid,
      },
      null
    );
  };

  const handleSetPayStatusNeedReturn = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_need_return_pay__status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setPayStatusNeedReturn,
      },
      null
    );
  };

  const handleSetPayStatusCanceled = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_canceled_pay_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setPayStatusCanceled,
      },
      null
    );
  };

  const handleSetPayStatusReturned = () => {
    registerDialog(
      LL("confirm_action"),
      `${LL(
        "confirm_action_returned_pay_status_for_order"
      )} ${CommonTools.processObjectField(object, ["id"])}`,
      {
        cb: setPayStatusReturned,
      },
      null
    );
  };

  const cb = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.error) return;
    callbackFunction();
  };

  return {
    handleSetNewStatus,
    handleSetStatusVerified,
    handleSetStatusInProcess,
    handleSetStatusProcessed,
    handleSetStatusOnCourier,
    handleSetStatusDelivered,
    handleSetStatusArchived,
    handleSetStatusCancelled,
    handleSetStatusReturned,
    handleSetPayStatusPaid,
    handleSetPayStatusNeedReturn,
    handleSetPayStatusCanceled,
    handleSetPayStatusReturned,
  };
};
