import {
  ServerCategoryBlockSelect,
} from "components/elements/select/ServerCategoryBlockSelect";
import RequestListDTO from "dto/app/requestlist.dto";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import React from "react";
import { CategoryBlogService } from "services/static/categoryblog.service";


type Props = {
  field?: string;
  value?: string;
  setObjectField: (field: string, value: any) => void;
  label?: string;
};
const service = new CategoryBlogService();
const SelectCategoryBlog: React.FC<Props> = ({
  field = "idcategory",
  value,
  setObjectField,
  label = "category",
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerCategoryBlockSelect
      setObjectField={setObjectField}
      value={value}
      getList={getList}
      processOptions={CategoryBlogDto.parseToSelectOptions}
      field={field}
      label={label}
    />
  );
};

export { SelectCategoryBlog };
