import { Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { OfferFilterDto } from "dto/product/offerfilter.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { OfferFilterService } from "services/product/offerfilter.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { useDetailSpecialThirdLevel } from "hooks/useDetailSpecialThirdLevel";
import { HeaderDetailSpecialThirdLevel } from "components/elements/detail/HeaderDetailSpecialThirdLevel";

const service = new OfferFilterService();
const SpecialOfferFilterDetail: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    firstLevelParentId,
    firstLevelParentType,
  } = useDetailSpecialThirdLevel<OfferFilterDto>(
    "offerfilter",
    currentRoute,
    get,
    false,
    undefined,
    5
  );

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecialThirdLevel
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        specialType="offerfilter"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={OfferFilterDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialOfferFilterDetail };
