import React from "react";

import { IPage } from "interfaces/page.interface";
import {ConfigService} from "services/system/config.service";
import { ConfigDto } from "dto/static/config.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormConfig } from "components/static/config/FormConfig";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Grid } from "@mui/material";
import { DetailGrid } from "components/elements/detail/DetailGrid";

const service = new ConfigService();
const EditConfig: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id } =
    useEditObject<ConfigDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: ConfigDto) => {
    if (!obj) return;
    setLoading(true);

    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CommonTools.parseDetailFields(
              ["identifier", "type"],
              ConfigDto.getDetailFields()
            )}
          />
        </Grid>
        <Box mt={3}>
          <FormConfig
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditConfig };
