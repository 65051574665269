import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { useState, useEffect, useMemo, useCallback } from "react";

type Props = (getFilters: () => Promise<TbFilterListDTO>) => {
  filter: TbFilterListDTO | null;
  loadingFilters: boolean;
};

const useAsyncFilter: Props = (getFilters) => {
  const [filter, setFilter] = useState<TbFilterListDTO | null>(null);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(true);

  const getData = async () => {
    try {
      const objects = await getFilters();
      setFilter(objects);
    } catch (e) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!filter) loading = true;
    setLoadingFilters(loading);
  }, [filter]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  return useMemo(
    () => ({
      filter,
      loadingFilters,
    }),
    [filter, loadingFilters]
  );
};

export { useAsyncFilter };
