import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export type Time = {
  hour: number;
  minute: number;
};
export class DateTools {
  static getDateStrFromTimestamp(timestamp: number | undefined): string {
    if (!timestamp) return "";
    return DateTools.getDateStr(this.convertTimestampToDate(timestamp));
  }

  static getDateStr(obj: Date | null): string {
    if (!obj) return "";

    return dayjs(obj.toISOString()).format(DateTools.getDateFormatDayJs());
  }

  static getDateStrForDB(obj: Date | null): string {
    if (!obj) return "";

    return dayjs(obj.toISOString()).format(DateTools.getDateFormatDayJsForDB());
  }

  static convertDateFromDBToDate(date?: string): Date {
    if (!date) return new Date();
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) return new Date();
    else if (newDate.toString() === "Invalid Date") return new Date();
    else if (newDate.toString() === "NaN") return new Date();
    else if (newDate.getFullYear() < 1900) return new Date();
    else return newDate;
  }

  static getDateTimeStamp(obj: Date | null): number | null {
    if (!obj) return null;

    const diffLocale = -1 * obj.getTimezoneOffset() * 60000;
    const timestamp = obj.getTime() + diffLocale;

    return Math.round(timestamp / 1000);
  }

  static getDateFormat(): any {
    return "dd.MM.yyyy";
  }

  static getDateFormatDayJs(): any {
    return "DD.MM.YYYY";
  }

  static getDateFormatDayJsForDB(): any {
    return "YYYY-MM-DD";
  }

  static getDateTimeFormat(): any {
    return "dd.MM.yyyy HH:mm";
  }

  static getDayJsDate(obj: Date | null | undefined): Dayjs {
    if (!obj) return dayjs();
    return dayjs(obj)
  }

  static timestampToDayJs(timestamp: number | undefined | null): Dayjs {
    if (!timestamp) return dayjs().utc();

    return dayjs(this.convertTimestampToDate(timestamp)).utc();
  }
  static dayJsToTimestamp(dayjs?: Dayjs, time?: Time): number {
    if (!dayjs) return 0;
    if (time) {
      dayjs = dayjs.hour(time.hour);
      dayjs = dayjs.minute(time.minute);
      dayjs = dayjs.second(0);
      dayjs = dayjs.millisecond(0);
    }

    return dayjs.utc().unix();
  }
  static dayJsToDate(obj: any): Date {
    if (!obj) return new Date();
    return obj.toDate();
  }

  static getDate(incrementSecond?: any): any {
    incrementSecond = incrementSecond ? parseInt(incrementSecond) : 0;
    incrementSecond = isNaN(incrementSecond) ? 0 : incrementSecond;

    var rez = new Date();
    rez.setSeconds(rez.getSeconds() + incrementSecond);

    return rez;
  }

  static getTimeStamp(incrementSecond?: any, time?: Time): number {
    const date = this.getDate(incrementSecond);
    if (time) {
      date.setMinutes(time.minute);
      date.setHours(time.hour);
    }
    let timestamp = date.getTime();
    timestamp = timestamp / 1000;
    timestamp = Math.round(timestamp);

    return timestamp;
  }

  public static convertTimestampToDate(timestamp: number | undefined): Date {
    if (!timestamp) return new Date();
    const t = timestamp * 1000;
    return new Date(t);
  }

  public static displayMMSS(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  public static convertToDate(value: any): Date {
    return new Date(value);
  }

  public static displayTimestampInHHMM(timestamp: number | undefined): string {
    if (!timestamp) return "";
    const secondsPerHour = 3600;
    const secondsPerMinute = 60;

    const hours = Math.floor(timestamp / secondsPerHour);
    const remainingSeconds = timestamp % secondsPerHour;
    const minutes = Math.floor(remainingSeconds / secondsPerMinute);

    return `${hours}:${minutes}`;
  }

  public static displayTimestampInDateAndHHMM(
    timestamp: number | undefined
  ): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  public static timeAgo(timestamp: number | undefined, LL?: any): string {
    if (!timestamp) {
      return "";
    }

    const now = dayjs();
    const targetDate = dayjs(this.convertTimestampToDate(timestamp));
    const diff = now.diff(targetDate, "minute");
    const hours = now.diff(targetDate, "hour");
    const days = now.diff(targetDate, "day");

    if (diff < 60) {
      if (LL) return diff.toString() + " " + LL("minutes_ago");
      else return diff.toString();
    } else if (hours < 24) {
      if (LL) return hours.toString() + " " + LL("hours_ago");
      else return hours.toString();
    } else if (days <= 7) {
      if (LL) return days.toString() + " " + LL("days_ago");
      else return days.toString();
    } else {
      return targetDate.format(this.getDateFormatDayJs());
    }
  }
}
