import React from "react";
import { IPage } from "interfaces/page.interface";

import { StaticHTTPCodePage } from "components/general/StaticHTTPCodePage";

const NotFoundPage: React.FC<IPage> = () => {
  return <StaticHTTPCodePage code={"404"} label={"404_notFound"} />;
};

export { NotFoundPage };
