import { Config } from "tools/utils/config";
import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class FileRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("file");
  }

  uploadImageCkEditor = () => {
    return `${Config.API_URL}${this.mainUrl}/uploadimage`;
  };
  uploadFileCkEditor = () => {
    return `${Config.API_URL}${this.mainUrl}/uploadfile`;
  };
}
