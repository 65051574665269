import { PreviewButton } from "components/elements/button/PreviewButton";
import { PageDto } from "dto/static/page.dto";
import React from "react";
import { PageType } from "tools/types/pagetype";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  obj?: PageDto;
};

const PreviewPage: React.FC<Props> = ({ obj }) => {
  if (!obj) return null;
  if (obj.type !== PageType.CONTENT) return null;
  return <PreviewButton url={CommonTools.processObjectField(obj, ["_urlObj", "urlfull"])} />;
};

export { PreviewPage };
