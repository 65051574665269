import React, { useState } from "react";
import { CategoryBlogService } from "services/static/categoryblog.service";

import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormCategoryBlog } from "components/static/categoryblog/FormCategoryBlog";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";

const service = new CategoryBlogService();
const AddCategoryBlog: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: CategoryBlogDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormCategoryBlog
          defaultObject={
            new CategoryBlogDto(
              idSelectedLanguage,
              CommonTools.processObjectField(currentRoute, [
                "historystate",
                "idParent",
              ])
            )
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddCategoryBlog };
