import React from "react";

import { IPage } from "interfaces/page.interface";
import { ProductFilterService } from "services/product/productfilter.service";
import { ProductFilterDto } from "dto/product/productfilter.dto";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FormProductFilter } from "components/special/productfilter/FormProductFilter";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";

import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";

const service = new ProductFilterService();
const SpecialProductFilterEdit: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,

    parentId,
    parentType,
  } = useEditSpecial<ProductFilterDto>("productfilter", mainObject, currentRoute, get);

  const onSubmit = (obj: ProductFilterDto) => {
    if (!obj) return;
    setLoading(true);

    obj.idproduct = parseInt(parentId.toString());
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "productfilter",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        parentId={parentId}
        parentType={parentType}
        specialType="productfilter"
      />
      <DataDisplayBox>
        <Box mt={3}>
          <FormProductFilter
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialProductFilterEdit };
