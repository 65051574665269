import { Box } from "@mui/material";
import { AclRolesDto } from "dto/user/aclroles.dto";
import React from "react";
import { AclRoleItem } from "./AclRoleItem";

import { GroupAction } from "./GroupAction";
import { useResource } from "hooks/useResource";

type Props = {
  objects: AclRolesDto[];
  idRole: string;
  group: string;
};

const AclRoleList: React.FC<Props> = ({ objects, idRole, group }) => {
  const {triggerAclRolesGlobal} = useResource()
  const processItem = (item: AclRolesDto, index: number) => {
    return (
      <AclRoleItem key={index} item={item} idRole={idRole} group={group} />
    );
  };
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box>
      <Box>
        <Box>
          <GroupAction
            groupName={group}
            idRole={idRole}
            trigger={triggerAclRolesGlobal}
          />
        </Box>
        <Box>{objects.map((item, index) => processItem(item, index))}</Box>
      </Box>
    </Box>
  );
};

export { AclRoleList };
