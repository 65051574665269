import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyFileField } from "components/elements/form/MyFileField";
import { MyTextField } from "components/elements/form/MyTextField";
import { ConfigFileDto } from "dto/system/configfile.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: ConfigFileDto;
  onSubmit: (data: ConfigFileDto) => void;
  loading: boolean;
};
const FormConfigFile: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , , setObject,vResults] = useForm<ConfigFileDto>(
    defaultObject,
    RequiredValidator.getValidators(["file"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyFileField
            field="file"
            label={LL("field_attachment")}
            setObj={setObject}
            obj={obj}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="comment"
            label={LL("field_comment")}
            setObjectField={setObjField}
            value={obj.comment}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormConfigFile };
