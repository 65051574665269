import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";

import { Grid, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { DictionaryDTO } from "dto/app/dictionary.dto";


const DetailGridConfig: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  if (!item) return null;
  if (!item.options) return null;
  if (!item.options.LL) return null;
  const { LL } = item.options;
  const mt = index === 0 ? 0 : 3;
  const arr = CommonTools.processObjectFieldArray(object, item.value);
  if (!arr || arr.length === 0) return null;
  
  return (
    <React.Fragment>
      {arr.map((item: DictionaryDTO, index: number) => {
        return (
          <React.Fragment key={index}>
            <Grid mt={mt}>
              <Typography color="text.disabled" variant="caption">
                {LL(item.key)}
              </Typography>
            </Grid>
            <Grid>{CommonTools.processObjectField(item, ["value"])}</Grid>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export { DetailGridConfig };
