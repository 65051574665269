import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";
import { Status } from "tools/types/status";
import { UserDto } from "dto/user/user.dto";
import { RoleDto } from "dto/user/role.dto";
import { JournalType } from "tools/types/jurnaltype";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

export class OrderJournalDto implements Idto {
  id?: number | string;

  status?: number;
  paystatus?: number;
  paymethod?: number;

  idorder?: number | string;
  data?: number;
  jurnaltype?: number;

  idrole?: number | string;
  iduser?: number | string;

  note?: string;

  userObj?: UserDto;
  roleObj?: RoleDto;

  constructor(
    id?: number | string,
    status?: number,
    paystatus?: number,
    paymethod?: number,
    idorder?: number | string,
    data?: number,
    jurnaltype?: number,
    idrole?: number | string,
    iduser?: number | string,
    note?: string
  ) {
    this.id = id || 0;
    this.status = status || Status.ACTIVE;
    this.paystatus = paystatus || Status.ACTIVE;
    this.paymethod = paymethod || 0;
    this.idorder = idorder || 0;
    this.data = data || 0;
    this.jurnaltype = jurnaltype || JournalType.ADD_ORDER;
    this.idrole = idrole || 0;
    this.iduser = iduser || 0;
    this.note = note || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "paystatus",
        value: ["paystatus_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "paymethod",
        value: ["paymethod_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Data",
        value: ["data"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "jurnaltype",
        value: ["jurnaltype_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Role",
        value: ["roleObj", "identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "User",
        value: ["userObj", "screen_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Note",
        value: ["note"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "data"),
      ComponentCommonTools.columns_ConstantColumn(LL, "jurnaltype"),
      ComponentCommonTools.columns_ConstantColumn(LL, "paystatus"),
      ComponentCommonTools.columns_ConstantColumn(LL, "paymethod"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "roleObj",
        "identifier"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "userObj",
        "screen_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "data"),
      ComponentCommonTools.columns_ConstantColumn(LL, "jurnaltype"),
      ComponentCommonTools.columns_ConstantColumn(LL, "paystatus"),
      ComponentCommonTools.columns_ConstantColumn(LL, "paymethod"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "roleObj",
        "identifier"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "userObj",
        "screen_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["delete", "detailspecial"],
        ["detailspecial"],
        undefined,
        "orderjournal"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<OrderJournalDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["identifier"])
        )
      );
    });
    return result;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idorder";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idorder") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
