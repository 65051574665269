import React from "react";

import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useUser } from "hooks/useUser";
import { Stack, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";

import { RouteTools } from "tools/utils/route.tool";
import { MyButton } from "components/elements/button/MyButton";

const CurrentUserInfo: React.FC = () => {
  const { logout, user } = useUser();

  const handleLogOut = () => {
    logout();
  };

  const handleNav = () => {
    RouteTools.setHistory("/profile",{});
  }

  return !user ? null : (
    <Stack
      className="userInfo"
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={1}
    >
      <MyButton cb={handleNav} variant="text" sx={{
        color: "#000",
        textTransform: "none",
      }}>
        <Typography>
          {CommonTools.processObjectField(user, ["screen_name"])}
        </Typography>
      </MyButton>
      <IconButton
        aria-label="delete"
        onClick={handleLogOut}
        sx={{ color: "primary" }}
      >
        <ExitToAppIcon />
      </IconButton>
    </Stack>
  );
};

export { CurrentUserInfo };
