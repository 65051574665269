import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import { FilterTools } from "tools/utils/filter.tool";
import { FromEmailDto } from "./fromemail.dto";
import { Status } from "tools/types/status";
import { Params } from "dto/system/params.dto";

export class EmailTemplateDto implements Idto {
  id?: number | string;
  identifier?: string;
  status?: number;
  toemail?: string;
  idfromemail?: number;
  replyto?: string;
  cc?: string;
  bcc?: string;

  _idlang?: number | string;
  _subject?: string;
  _fromname?: string;
  _toname?: string;

  _message?: string;

  fromEmailObj?: FromEmailDto;

  parameters: Array<Params>;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    identifier?: string,
    status?: number,
    toemail?: string,
    idfromemail?: number,
    replyto?: string,
    cc?: string,
    bcc?: string,
    _subject?: string,
    _fromname?: string,
    _toname?: string,
    _message?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.status = status || Status.ACTIVE;
    this.toemail = toemail || "";
    this.idfromemail = idfromemail || 0;
    this.replyto = replyto || "";
    this.cc = cc || "";
    this.bcc = bcc || "";
    this._idlang = _idlang || 0;
    this._subject = _subject || "";
    this._fromname = _fromname || "";
    this._toname = _toname || "";
    this._message = _message || "";
    this.parameters = [];
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "fromemail",
        value: ["fromEmailObj", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "toemail",
        value: ["toemail"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "replyto",
        value: ["replyto"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "cc",
        value: ["cc"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "bcc",
        value: ["bcc"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "subject",
        value: ["_subject"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "fromname",
        value: ["_fromname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "toname",
        value: ["_toname"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "message",
        value: ["_message"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "fromEmailObj",
        "email"
      ),
      ComponentCommonTools.columns_StandardColumn(LL, "toemail"),
      ComponentCommonTools.columns_StandardColumn(LL, "replyto"),
      ComponentCommonTools.columns_StandardColumn(LL, "_subject"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();
    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<EmailTemplateDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(EmailTemplateDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: EmailTemplateDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["identifier"])
    );
  };
}

