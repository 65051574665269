import { Box } from "@mui/material";

import RequestListDTO from "dto/app/requestlist.dto";
import React, { useState } from "react";
import { HasId, KeyObject } from "./ServerCategoryBlockSelect";
import SelectOptions from "dto/app/selectoptions.dto";
import { MySelect } from "./MySelect";
import { ServerCategoryLevelSelect } from "./ServerCategoryLevelServer";


type Props = {
  setObjectField: (field: string, value: any) => void;
  objects: SelectOptions[];
  level: number;
  field: string;
  getList: (cb?: any, cbParams?: any, req?: RequestListDTO) => void;
  processOptions: (objects: HasId[]) => Array<SelectOptions>;
  keyList: KeyObject;
  addToList: (key: number, value: string | number) => void;
  removeFromList: (key: number) => void;
  label:string
};

const ServerCategoryItemSelect: React.FC<Props> = ({
  setObjectField,
  objects,
  level,
  field,
  getList,
  processOptions,
  keyList,
  addToList,
  removeFromList,
  label
}) => {
  const [valueSelect, setValueSelect] = useState<number | string | undefined>(
    keyList[level]
  );
  const newLevel = level + 1;
  const previousLevel = level - 1;

  const setObjectValue = (field: string, value: any) => {
    if (!value) {
      setObjectField(field, previousLevel >= 0 ? keyList[previousLevel] : "");
      removeFromList(level);
    } else {
      setObjectField(field, value);
      addToList(level, value);
    }
    setValueSelect(value);
  };

  const processChildren = () => {
    if (!valueSelect) return null;

    return (
      <ServerCategoryLevelSelect
        setObjectField={setObjectField}
        getList={getList}
        idParent={valueSelect.toString()}
        level={newLevel}
        list={keyList}
        processOptions={processOptions}
        field={field}
        addToList={addToList}
        removeFromList={removeFromList}
        label={label}
      />
    );
  };

  return (
    <Box>
      <Box>
        <MySelect
          field={field}
          setObjectField={setObjectValue}
          value={valueSelect}
          options={objects}
          _label={label}
        />
      </Box>
      <Box mt={3}>{processChildren()}</Box>
    </Box>
  );
};

export { ServerCategoryItemSelect };
