import React, { createContext, useCallback, useEffect, useState } from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import { LocalStorageTools } from "api/localstorage.api";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import { AclService } from "services/system/acl.service";
import { AclDto } from "dto/system/route.dto";

type Props = {
  hasPermission: (route: string, idRoles: number[]) => boolean;
};
export const AclContext = createContext<Props>({
  hasPermission: () => false,
});

const getLocalAcls = (): Array<AclDto> => {
  const acls = LocalStorageTools.getObject("acls");
  if (!acls) return [];
  return acls;
};

var aclsLocalGlobal: Array<AclDto> = getLocalAcls();

const service = new AclService();

export const AclProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hashLocal, setHashLocal] = useState<string>(getLocalHash());

  const [hash, setHash] = useState<string>("");
  const [aclLength, setAclLength] = useState<number>(getLocalAcls().length);

  const getHash = () => {
    service.getHash(handleGetHash, {});
  };

  const handleGetHash = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const hash = CommonTools.processObjectField(result, ["obj", "hash"]);
    setHash(hash);
  };

  useEffect(() => {
    getHash();
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!hash) loading = true;
    if (!aclLength) loading = true;
    setLoading(loading);
  }, [hash, aclLength]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const loadAcls = () => {
    service.getAccessRoutes(handleGetList, {}, new RequestListDTO([], 1, -1));
  };

  const handleGetList = (result: ResultListDTO<AclDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.objects) return;

    processAcls(result.objects);
  };

  const processAcls = (acls: Array<AclDto>) => {
    aclsLocalGlobal = acls;
    setAclLength(acls.length);
    // logger("processAcls", acls);
    LocalStorageTools.saveObject("acls", acls);
    LocalStorageTools.saveValue("acl_hash", hash);
    setHashLocal(hash);
  };

  const checkAclHash = useCallback(() => {
    if (!hash) return;
    if (hash === hashLocal) return;
    else {
      aclsLocalGlobal = [];
      setAclLength(0);
      loadAcls();
    }
  }, [hash, hashLocal]);

  useEffect(() => {
    checkAclHash();
  }, [checkAclHash]);

  const hasPermission = (route: string, idRoles: number[]): boolean => {
    const acl = aclsLocalGlobal.find(
      (a) => AclDto.getFullRouteName(a) === route
    );

    if (!acl) return true;
    if (!acl.acceptedidsrole.length) return false;
    if (acl.acceptedidsrole.includes(-1)) return true;
    if (idRoles.some((id) => acl.acceptedidsrole.includes(id))) return true;
    return false;
  };

  const value = {
    hasPermission,
  };

  return loading ? (
    <Loading />
  ) : (
    <AclContext.Provider value={value}>{children}</AclContext.Provider>
  );
};

const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("acl_hash");
  if (!hash) return "";
  return hash;
};
