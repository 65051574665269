import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { useLabel } from "hooks/useLabel";
const HomePage: React.FC<IPage> = () => {
  const { LL } = useLabel();
  return <Box>{LL("homepage")}</Box>;
};

export { HomePage };
