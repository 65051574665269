import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { RoleDto } from "dto/user/role.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: RoleDto;
  onSubmit: (data: RoleDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormRole: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,,vResults] = useForm<RoleDto>(
    defaultObject,
    RequiredValidator.getValidators(["identifier"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>

        <Box mt={3}>
          <MyCheckboxField
            field="isdefault"
            label={LL("field_isdefault")}
            setObjectField={setObjField}
            checked={CommonTools.processNumberToBoolean(obj.isdefault)}
            isNumber
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormRole };
