import { OptionsDetailObject } from "interfaces/detailobject.interface";
import React from "react";

import { Link, Stack, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";

import { Types } from "tools/types/types";
import { Image } from "components/elements/display/Image";
import { VideoPlayer } from "../display/VideoPlayer";

type Props = {
  obj: any;
  fields: string[];
  type?: number;
  options?: OptionsDetailObject;
};
const DataGridArrayItem: React.FC<Props> = ({
  obj,
  fields,
  type = Types.FIELD_TYPE_TEXT,
  options,
}) => {
  switch (type) {
    case Types.FIELD_TYPE_TEXT: {
      return (
        <Typography>{CommonTools.processObjectField(obj, fields)}</Typography>
      );
    }
    case Types.FIELD_TYPE_MULTI_TEXT: {
      const secondaryFields = options?.secondaryFields
        ? options.secondaryFields
        : [];
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
          
        >
          <Typography>{CommonTools.processObjectField(obj, fields)}</Typography>
          <Typography>-</Typography>
          <Typography>
            {CommonTools.processObjectField(obj, secondaryFields)}
          </Typography>
        </Stack>
      );
    }
    case Types.FIELD_TYPE_IMAGE: {
      return (
        <Image
          url={CommonTools.processObjectField(obj, fields)}
          typeMeasure="pixel"
          sizeInPx={{
            width: options?.width
              ? typeof options.width === "number"
                ? options.width
                : parseInt(options.width)
              : 100,
            height: options?.height
              ? typeof options.height === "number"
                ? options.height
                : parseInt(options.height)
              : 100,
          }}
          withLinK={options?.withLink || true}
        />
      );
    }
    case Types.FIELD_TYPE_FILE: {
      return (
        <Link
          href={CommonTools.processObjectField(obj, fields)}
          target="_blank"
          rel="noreferrer"
        >
          {CommonTools.processObjectField(obj, ["_name"])}
        </Link>
      );
    }
    case Types.FIELD_TYPE_VIDEO: {
      let width: string | number = 320;
      let height: string | number = 180;
      let controls = false;

      if (!options) return null;
      width = options.width ? options.width : 320;
      height = options.height ? options.height : 180;
      controls = options.controls ? options.controls : false;
      const secondaryFields = options?.secondaryFields
        ? options.secondaryFields
        : null;

      if (!secondaryFields) return null;
      return (
        <VideoPlayer
          videoId={CommonTools.processObjectField(obj, fields)}
          width={width}
          height={height}
          controls={controls}
          type={parseInt(CommonTools.processObjectField(obj, secondaryFields))}
        />
      );
    }
    default: {
      return null;
    }
  }
};
export { DataGridArrayItem };
