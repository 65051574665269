import React from "react";
import { AttachmentService } from "services/system/attachment.service";

import { IPage } from "interfaces/page.interface";

import { AttachmentDto } from "dto/system/attachment.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormAttachment } from "components/special/attachment/FormAttachment";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";
import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new AttachmentService();
const SpecialAttachmentAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: AttachmentDto) => {
    if (!obj) return;
    obj.id_parent = parseInt(parentId.toString());
    obj.parent = parentType;
    setLoading(true);
    service.addMultipart(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "attachment",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="attachment"
      />
      <DataDisplayBox>
        <FormAttachment
          defaultObject={
            new AttachmentDto(parseInt(idSelectedLanguage.toString()))
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialAttachmentAdd };
