import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const DataDisplayBox: React.FC<Props> = ({ children }) => {
  return (
    <Box
      maxWidth="100%"
      className="shadowBox"
      sx={{ bgcolor: "background.paper", pt: 4, pb: 8, px: 6 }}>
      {children}
    </Box>
  );
};

export { DataDisplayBox };
