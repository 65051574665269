import { List, Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { OrderProductDto } from "dto/sale/orderproduct.dto";
import { useList } from "hooks/useList";
import React from "react";
import { OrderProductService } from "services/sale/orderproduct.service";
import { OrderProductItem } from "./OrderProductItem";

type Props = {
  idOrder: string;
};

const service = new OrderProductService();
const OrderProductList: React.FC<Props> = ({ idOrder }) => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    if (!idOrder) return;
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<OrderProductDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idorder", [idOrder])],
      1,
      -1
    ),
    [idOrder]
  );

  const processItem = (item: OrderProductDto, index: number) => {
    if (!item) return null;
    return <OrderProductItem object={item} key={index} />;
  };
  if (!idOrder) return null;
  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;
  return <List>{objects.map((item, index) => processItem(item, index))}</List>;
};

export { OrderProductList };
