import { HeaderListWithChildren } from "components/elements/list/HeaderListWithChildren";
import { MyTable } from "components/elements/table/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useLabel } from "hooks/useLabel";
import { useListAdminWithChildren } from "hooks/useListAdminWithChildren";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { NavigatorService } from "services/static/navigator.service";


const service = new NavigatorService();
const Navigator: React.FC<IPage> = ({ currentRoute, mainObject }) => {
    const getListFun = (cb: any, cbParams: any, reqList: any) => {
        service.getList(cb, cbParams, reqList);
      };
      const getRequestListFun = (req: RequestListDTO, idParent: string) => {
        return service.getDefaultRequestListParent(req, idParent);
      };
      const deleteFun = (id: string, cb?: any, params?: any) => {
        service.delete(id, cb, params);
      };
      const getObjectFun = (id: string, cb: any, cbParams: any) => {
        service.get(id, cb, cbParams);
      };
      const {
        addUrl,
        mainUrl,
        setDeleteOneObject,
        handleDelete,
        rows,
        total,
        totalPage,
        deleteOneObject,
        reqList,
        setReqList,
        loading,
        goUp,
        object,
        setParent,
        idParent,
      } = useListAdminWithChildren<NavigatorDto>(
        mainObject,
        currentRoute,
        getListFun,
        getRequestListFun,
        deleteFun,
        getObjectFun
      );
      const { LL } = useLabel();
      if (!currentRoute) return null;
      if (!mainObject) return null;
      if (!reqList) return null;
      return (
        <React.Fragment>
          <HeaderListWithChildren
            mainObject={mainObject}
            mainUrl={mainUrl}
            addUrl={addUrl}
            goUp={goUp}
            object={object}
            idParent={idParent}
          />
          <MyTable
            _columns={NavigatorDto.getColumns(
              LL,
              mainObject,
              setDeleteOneObject,
              setParent
            )}
            _data={rows}
            _total={total}
            _totalPage={totalPage}
            _reqList={reqList}
            _tbFilterList={NavigatorDto.getFilters()}
            setReqList={setReqList}
            loading={loading}
            currentRoute={currentRoute}
            onDelete={handleDelete}
            mainObject={mainObject}
            fieldToShowOnDelete="identifier"
            deleteOneObject={deleteOneObject}
            setDeleteOneObject={setDeleteOneObject}
          />
        </React.Fragment>
      );
};

export { Navigator };
