import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";

import { StatusSelect } from "components/elements/select/StatusSelect";
import { SelectTypeLocation } from "components/nomenclature/SelectTypeLocation";
import { LanguageSelect } from "components/static/language/LanguageSelect";

import { LocationDto } from "dto/static/location.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";
import { AutocompleteCountry } from "../country/AutocompleteCountry";

type Props = {
  defaultObject: LocationDto;
  onSubmit: (data: LocationDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormLocation: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setLanguage, , vResults] =
    useForm<LocationDto>(
      defaultObject,
      RequiredValidator.getValidators(["_name"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <SelectTypeLocation
            value={obj.idtypelocation}
            setObjectField={setObjField}
          />
        </Box>
        {!obj.idparent || obj.idparent === "0" ? (
          <Box mt={3}>
            <AutocompleteCountry
              setObjField={setObjField}
              defaultObject={obj.countryObj}
              value={obj.idcountry}
            />
          </Box>
        ) : null}
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormLocation };
