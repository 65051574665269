import React from "react";
import { MyBreadcrumbs } from "../breadcrumb/MyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { useLabel } from "hooks/useLabel";
import { Box, Typography } from "@mui/material";
import { NavButton } from "../button/NavButton";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  showAddButton?: boolean;
  stateParams?: object;
};
const HeaderList: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  showAddButton = true,
  stateParams = {},
}) => {
  const { LL } = useLabel();
  const access = RouteTools.checkRoutePermission(
    RouteTools.getPmHash_Add(mainObject)
  );

  return (
    <React.Fragment>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 3 }}>
          {LL(mainObject + "page")}
        </Typography>

        {showAddButton && access && (
          <NavButton
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={stateParams}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderList };
