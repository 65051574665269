import React from "react";
import { IPage } from "interfaces/page.interface";
import { TypeLocationService } from "services/nomenclature/typelocation.service";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";

const service = new TypeLocationService();

const TypeLocation: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeLocation: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeLocation: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeLocation: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export { TypeLocation, DetailTypeLocation, AddTypeLocation, EditTypeLocation };
