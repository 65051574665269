import { Link } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
type Props = {
  url: string;
};

const PreviewButton: React.FC<Props> = ({ url }) => {
  const { LL } = useLabel();
  if (!url) return null;
  return (
    <Link
      href={url}
      target="_blank"
      rel="noopener"
      className="detailBtn"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        direction: "row",
      }}
    >
      <VisibilityIcon
        sx={{
          marginRight: 1,
        }}
      />
      {LL("preview")}
    </Link>
  );
};

export { PreviewButton };
