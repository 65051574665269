import { Box, Skeleton } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { SelectFilterAndType } from "components/product/filter/SelectFilterAndType";
import { SelectFilterDictionar } from "components/product/filterdictionar/SelectFilterDictionar";
import { ProductFilterDto } from "dto/product/productfilter.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React, { useState } from "react";
import { FilterType } from "tools/types/filtertype";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: ProductFilterDto;
  onSubmit: (data: ProductFilterDto) => void;
  loading: boolean;
};
const FormProductFilter: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();
  const [typeFilter, setTypeFilter] = useState<number>(0);
  const [obj, disabled, setObjField, , , setObject] = useForm<ProductFilterDto>(
    defaultObject,
    RequiredValidator.getValidators(["idfilter"])
  );

  const setFilter = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    if (typeFilter === FilterType.DICTIONAR) t["idfilterdictionar"] = "";
    setObject(t);
  };
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectFilterAndType
            setObjectField={setFilter}
            setType={setTypeFilter}
            value={obj.idfilter}
          />
        </Box>
        <Box mt={3}>
          {typeFilter === FilterType.DICTIONAR && obj.idfilter ? (
            <SelectFilterDictionar
              setObjectField={setObjField}
              value={obj.idfilterdictionar}
              idFilter={obj.idfilter}
            />
          ) : typeFilter === FilterType.VALUE ? (
            <MyTextField
              field="filtervalue"
              label={LL("field_filtervalue")}
              setObjectField={setObjField}
              value={obj.filtervalue}

            />
          ) : (
            <Skeleton />
          )}
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormProductFilter };
