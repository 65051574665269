import {  Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { CountryDto } from "dto/static/country.dto";
import { useDetailObject } from "hooks/useDetailObject";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { CountryService } from "services/static/country.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

// import { useLabel } from "hooks/useLabel";
// import { TabPanel } from "components/elements/display/TabPanel";
// import { DetailCountryLang } from "components/countrylang/DetailCountryLang";
// import { CommonTools } from "tools/common.tool";
// import { DetailCountryCurrency } from "components/countrycurrency/DetailCountryCurrency";
const service = new CountryService();
const DetailCountry: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    listUrl,
    object,
    loading
    // handleChangeTab,
    // tabValue,
  } = useDetailObject<CountryDto>(mainObject, currentRoute, get);

  // const { LL } = useLabel();

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;
  if (!object.id) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        
      />
      {/* <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Detail_Country_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Detail_language")} value={"language"} />
          <Tab label={LL("Detail_currency")} value={"currency"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}> */}
        <DataDisplayBox>
          <Grid>
            <DetailGrid object={object} fields={CountryDto.getDetailFields()} />
          </Grid>
        </DataDisplayBox>
      {/* </TabPanel>
      <TabPanel value={tabValue} index={"language"}>
        <DetailCountryLang
          idCountry={CommonTools.processObjectField(object, ["id"])}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={"currency"}>
        <DetailCountryCurrency
          idCountry={CommonTools.processObjectField(object, ["id"])}
        />
      </TabPanel> */}
    </React.Fragment>
  );
};

export { DetailCountry };
