import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { HtmlTag } from "../display/HtmlTag";
import { CommonTools } from "tools/utils/common.tool";
import { DetailGridItem } from "./DetailGridItem";

const DetailGridHtml: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  return (
    <DetailGridItem item={item} index={index}>
      <HtmlTag content={CommonTools.processObjectField(object, item.value)} />
    </DetailGridItem>
  );
};

export { DetailGridHtml };
