import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class LanguageRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("lang");
  }

  getListAdmin = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/adminlangs`;
    return await this.getListObject(url, cb, cbParams);
  };

  changeLanguageByCode = async (code: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/changelangcode/${code}`;
    return await this.getObject(url, cb, cbParams);
  }
}
