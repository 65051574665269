import { UserRoleDto } from "dto/user/userrole.dto";
import React, { useState } from "react";

import { FormUserRole } from "./FormUserRole";
import {UserRoleService} from "services/user/userrole.service";
import RequestListDTO from "dto/app/requestlist.dto";

import { IPage } from "interfaces/page.interface";
import { useListAdmin } from "hooks/useListAdmin";
import { MyTable } from "components/elements/table/MyTable";
import { useLabel } from "hooks/useLabel";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Box, Skeleton } from "@mui/material";

type Props = IPage & {
  idUser: number | string;
};
const service = new UserRoleService();
const AddUserRole: React.FC<Props> = ({ idUser, currentRoute, mainObject }) => {
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const { LL } = useLabel();

  const getListFun = (cb: any, cbParams: any, reqList: RequestListDTO) => {
    setLoadingForm(false);

    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    if (!idUser) return;
    const filter = new RequestFilterDTO();
    filter.field = "iduser";
    filter.values = [idUser.toString()];
    if (req.filters && req.filters.length > 0) {
      req.filters.push(filter);
    } else {
      req.filters = [filter];
    }
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };

  const {
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
    getList,
    
  } = useListAdmin<UserRoleDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun as any,
    deleteFun,
    false
  );

  const onSubmit = (obj: UserRoleDto) => {
    setLoadingForm(true);
    service.add(getList, {}, obj);
  };

  if (!idUser) return null;
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  
  return (
    <React.Fragment>
      {loadingForm ? (
        <Skeleton variant="rectangular" height={100} />
      ) : (
        <FormUserRole
          defaultObject={new UserRoleDto(undefined, idUser)}
          onSubmit={onSubmit}
          loading={loadingForm || loading}
        />
      )}
      <Box mt={3}>
        <MyTable
          _columns={UserRoleDto.getColumns(LL, mainObject, setDeleteOneObject)}
          _data={rows}
          _total={total}
          _totalPage={totalPage}
          _reqList={reqList}
          setReqList={setReqList}
          loading={loading}
          currentRoute={currentRoute}
          onDelete={handleDelete}
          mainObject={mainObject}
          fieldToShowOnDelete="role"
          deleteOneObject={deleteOneObject}
          setDeleteOneObject={setDeleteOneObject}
          onRowClickCb={() => {}}
        />
      </Box>
    </React.Fragment>
  );
};

export { AddUserRole };
