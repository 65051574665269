import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import React from "react";
import { Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "../button/NavButton";

type Props = {
  item: BreadcrumbDto;
  index: number;
  mainObject: string;
};

const BreadcrumbItem: React.FC<Props> = ({ item, index, mainObject }) => {
  const { LL } = useLabel();
  const mainUrl = CommonTools.generateMainUrl(mainObject);
  return item.url ? (
    <NavButton
      href={item.url}
      underline="hover"
      key={index}
      _mainurl={mainUrl}
      color="text.secondary"
    >
      {LL(CommonTools.processObjectField(item, ["label"]))}
    </NavButton>
  ) : (
    <Typography key={index} color="text">
      {LL(CommonTools.processObjectField(item, ["label"]))}
    </Typography>
  );
};

export { BreadcrumbItem };
