import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { GeneralService } from "services/general.service";

export class CategoryProductDto implements Idto {
  id?: number | string;
  identifier?: string;
  order?: number;

  status?: number;

  hierarchicalcode?: string;
  level?: number;
  idparent?: number | string;

  _idlang?: number | string;
  showonhome?: number;
  _name?: string;
  _title?: string;
  _slug?: string;
  _description?: string;
  _shortdescription?: string;
  _metakey?: string;
  _metadescription?: string;

  constructor(
    _idlang?: number | string,
    idparent?: number | string,
    identifier?: string,
    id?: number | string,
    order?: number,
    status?: number,
    _name?: string,
    _title?: string,
    _slug?: string,
    _description?: string,
    _shortdescription?: string,
    _metakey?: string,
    _metadescription?: string,
    showonhome?: number
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this._title = _title || "";
    this._slug = _slug || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
    this._metakey = _metakey || "";
    this._metadescription = _metadescription || "";
    this.identifier = identifier || "";
    this.idparent = idparent || 0;
    this.showonhome = showonhome || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "showonhome",
        value: ["showonhome_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["_title"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "slug",
        value: ["_slug"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "description",
        value: ["_description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["_shortdescription"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "metakey",
        value: ["_metakey"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "metadescription",
        value: ["_metadescription"],
      },
    ].concat(CommonTools.displayMedia());
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL, "order"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "edit",
          "detail",
          "delete",
          "attachment",
          "gallery",
          "video",
          "children",
        ],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<CategoryProductDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    if (!GeneralService._LL) return [];
    let result: Array<SelectOptions> = [
      new SelectOptions("", GeneralService._LL("Not_selected")),
    ];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["_name"])
        )
      );
    });

    return result;
  }
}
