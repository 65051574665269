export class PrepareObjectToUpdateDto {
  id: string;
  obj: object;
  _mainurl: string;
  anchor: string;
  idLanguage: string;
  constructor(
    id: string,
    obj: object,
    _mainurl: string,
    anchor?: string,
    idLanguage?: string
  ) {
    this.id = id;
    this.obj = obj;
    this._mainurl = _mainurl;
    this.anchor = anchor ?? "";
    this.idLanguage = idLanguage ?? "";
  }
}
