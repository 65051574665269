import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { FileDto } from "dto/system/file.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { AdvertisementType } from "tools/types/advertisementtype";

import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { TargetType } from "tools/types/targettype";
import { Types } from "tools/types/types";

export class AdvertisementDto implements Idto {
  id?: number | string;

  identifier?: string;
  section?: number;
  type?: number;

  status?: number;
  order?: number;

  urltogo?: string;
  imgwhidth?: number;
  imgheight?: number;
  imgmobwhidth?: number;
  imgmobheight?: number;
  advwhidth?: number;
  advheight?: number;

  idfile?: number | string;
  idfilemob?: number | string;

  target?: string;
  specsection?: string;

  startdate?: number;
  enddate?: number;

  _idlang?: number | string;
  _name?: string;
  _btname?: string;

  _description?: string;
  _shortdescription?: string;

  _fileObj?: FileDto | null;
  _filemobObj?: FileDto | null;

  _filecdnurl?: string;
  _filemobcdnurl?: string;
  file: File | null;
  filemob: File | null;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    identifier?: string,
    section?: number,
    type?: number,
    status?: number,
    order?: number,
    urltogo?: string,
    imgwhidth?: number,
    imgheight?: number,
    imgmobwhidth?: number,
    imgmobheight?: number,
    advwhidth?: number,
    advheight?: number,
    target?: string,

    startdate?: number,
    enddate?: number,
    _name?: string,
    _btname?: string,
    _description?: string,
    _shortdescription?: string
  ) {
    this.id = id || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
    this.startdate = startdate || 0;
    this.enddate = enddate || 0;
    this.identifier = identifier || "";
    this.section = section || AdvertisementSection.HOME_TOP;
    this.type = type || AdvertisementType.IMAGE_SIMPLE;
    this.order = order || 0;
    this.urltogo = urltogo || "";
    this.imgwhidth = imgwhidth || 0;
    this.imgheight = imgheight || 0;
    this.imgmobwhidth = imgmobwhidth || 0;
    this.imgmobheight = imgmobheight || 0;
    this.advwhidth = advwhidth || 0;
    this.advheight = advheight || 0;
    this.target = target || TargetType.SELF;
    this._btname = _btname || "";
    this.file = null;
    this.filemob = null;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "startdate",
        value: ["startdate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "enddate",
        value: ["enddate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "description",
        value: ["_description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["_shortdescription"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "section",
        value: ["section_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "urltogo",
        value: ["urltogo"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "imgwhidth",
        value: ["imgwhidth"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "imgheight",
        value: ["imgheight"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "imgmobwhidth",
        value: ["imgmobwhidth"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "imgmobheight",
        value: ["imgmobheight"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "advwhidth",
        value: ["advwhidth"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "advheight",
        value: ["advheight"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "target",
        value: ["target"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "specsection",
        value: ["specsection"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "btname",
        value: ["_btname"],
      },
      {
        typeField: Types.FIELD_TYPE_FILE,
        label: "file",
        value: ["_filecdnurl"],
        options: {
          fields: ["_fileObj", "name"],
        },
      },
      {
        typeField: Types.FIELD_TYPE_FILE,
        label: "filemob",
        value: ["_filemobcdnurl"],
        options: {
          fields: ["_filemobObj", "name"],
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "section"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static parseForEdit(obj: AdvertisementDto): AdvertisementDto {
    return new AdvertisementDto(
      obj._idlang,
      obj.id,
      obj.identifier,
      obj.section,
      obj.type,
      obj.status,
      obj.order,
      obj.urltogo,
      obj.imgwhidth,
      obj.imgheight,
      obj.imgmobwhidth,
      obj.imgmobheight,
      obj.advwhidth,
      obj.advheight,
      obj.target,
      obj.startdate,
      obj.enddate,
      obj._name,
      obj._btname,
      obj._description,
      obj._shortdescription
    );
  }
  
  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(AdvertisementType.GA("general", true));
    obj.addFilter(t);

    t = new TbFilterDTO("section", FilterTools.fiterTypeSelectMultiple);
    t.setValues(AdvertisementSection.GA("general", true));
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<AdvertisementDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["_name"])
        )
      );
    });
    return result;
  }
}
