import { AclRepository } from "repositories/system/acl.repository";
import { GeneralService } from "services/general.service";

export class AclService extends GeneralService<AclRepository> {
  constructor() {
    super(new AclRepository());
  }

  getRoutes = async (
    cb?: any,
    cbparameters?: any,
    reqList?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getRoutes(
      this.handleGetList,
      cbparameters,
      reqList
    );

    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getAccessRoutes = async (
    cb?: any,
    cbparameters?: any,
    reqList?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getAccessRoutes(
      this.handleGetList,
      cbparameters,
      reqList
    );

    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getHash = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getHash(this.handleGet, cbparameters);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  
}
