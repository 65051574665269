import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Types } from "tools/types/types";
import { UserDto } from "dto/user/user.dto";
import { ProductDto } from "./product.dto";

export class ProductWishlistDto implements Idto {
  id?: number | string;
  idproduct?: number;
  iduser?: number;
  date?: number;
  userObj?: UserDto;
  productObj?: ProductDto;

  constructor(
    id?: number | string,
    idproduct?: number,
    iduser?: number,
    date?: number
  ) {
    this.id = id || 0;
    this.idproduct = idproduct || 0;
    this.iduser = iduser || 0;
    this.date = date || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "product",
        value: ["productObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "user",
        value: ["userObj", "screen_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "date",
        value: ["date_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "date"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "productObj",
        "_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [],
        [],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "date"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "productObj",
        "_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [],
        [],
        undefined,
        "productwishlist"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    return obj;
  }

  static parseToSelectOptions(
    data: Array<ProductWishlistDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(ProductWishlistDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: ProductWishlistDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["date"])
    );
  };

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "iduser";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "iduser") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
