import { Status } from "tools/types/status";
import { FileDto } from "./file.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";
import { ConfigTypes } from "tools/types/configtypes";

export class ConfigFileDto {
  id?: number;
  identifier?: string;
  comment?: string;
  type?: number;

  id_file?: number;
  _fileObj?: FileDto | null;

  fullurl?: string;
  downloadurl?: string;
  cdnurl?: string;

  file: File | null;

  constructor(
    id?: number,
    identifier?: string,
    comment?: string,
    type?: number,
    id_file?: number,
    _fileObj?: FileDto,
    fullurl?: string,
    downloadurl?: string,
    cdnurl?: string
  ) {
    this.id = id ?? 0;
    this.id_file = id_file ?? 0;
    this._fileObj = _fileObj ?? null;
    this.fullurl = fullurl ?? "";
    this.downloadurl = downloadurl ?? "";
    this.cdnurl = cdnurl ?? "";
    this.file = null;
    this.identifier = identifier ?? "";
    this.comment = comment ?? "";
    this.type = type ?? ConfigTypes.ADMIN;
  }

  static parseForEdit(obj: any): ConfigFileDto {
    return new ConfigFileDto(
      obj.id,
      obj.identifier,
      obj.comment,
      obj.type,
      obj.id_file,
      obj._fileObj,
      obj.fullurl,
      obj.downloadurl,
      obj.cdnurl
    );
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "comment",
        value: ["comment"],
      },
      {
        typeField: Types.FIELD_TYPE_FILE,
        label: "file",
        value: ["cdnurl"],
        options: {
          fields: ["_fileObj", "name"],
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL,"type"),
      ComponentCommonTools.columns_FileColumn(LL, "cdnurl"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
