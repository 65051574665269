import React, { useState } from "react";
import { Box, FormHelperText, TextField, TextFieldProps } from "@mui/material";
import ValidatorResult from "dto/app/validatorresult.dto";
import { Check, ReportGmailerrorred } from "@mui/icons-material";
import { CommonTools } from "tools/utils/common.tool";

export type Props = TextFieldProps & {
  field: string;
  label: string;
  setObjectField: (field: string, value: any) => void;
  vResults?: ValidatorResult;
};

const MyTextField: React.FC<Props> = ({
  label,
  field,
  setObjectField,
  vResults,
  ...props
}) => {
  const [wasEntered, setWasEntered] = useState(false);

  const infoButton = () => {
    if (field.includes("password")) return null;
    if (!wasEntered) return null;
    if (!vResults) return null;
    if (!vResults.hasOwnProperty(field)) return null;
    if (!vResults[field]) return null;
    if (vResults[field].isOk === "-1") return null;

    if (vResults[field].isOk) {
      return (
        <Box className="iconInput">
          <Check sx={{ color: "green" }} />
        </Box>
      );
    }

    return (
      <Box className="iconInput">
        <ReportGmailerrorred sx={{ color: "red" }} />
      </Box>
    );
  };

  const infoMessages = () => {
    if (!wasEntered) return null;
    if (!vResults) return null;
    if (!vResults.hasOwnProperty(field)) return null;
    if (!vResults[field]) return null;
    if (!vResults[field].objects) return null;
    if (!vResults[field].objects.length) return null;
    if (vResults[field].isOk === "-1") return null;

    const cl = vResults[field].isOk ? "active" : "disabled";

    return vResults[field].objects.map((_obj: any, i: number) => {
      if (!_obj.message) return null;

      return (
        <FormHelperText className={cl} key={i}>
          {CommonTools.processObjectField(_obj, ["message"])}
        </FormHelperText>
      );
    });
  };

  const handleOnBlur = () => {
    setWasEntered(true);
  };

  const handleChange = (event: any) => {
    setObjectField(field, event.target.value);
  };

  return (
    <Box
      sx={{
        position: "relative",
        right: "0px",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      <TextField
        label={label}
        fullWidth
        onFocus={handleChange}
        onChange={handleChange}
        onBlur={handleOnBlur}
        {...props}
      />
      {infoButton()}
      {infoMessages()}
    </Box>
  );
};

export { MyTextField };
