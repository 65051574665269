import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class OrderProductFilterRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("orderproductfilter");
  }

}
