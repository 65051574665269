import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";

export class NotificationDto implements Idto {
  id?: number | string;
  destinationtype?: number;
  priority?: number;
  idtemplate?: number;
  status?: number;
  datetosend?: number;
  timemintosend?: number;
  timemaxtosend?: number;
  idlangtpl?: number;

  constructor(
    id?: number | string,
    destinationtype?: number,
    priority?: number,
    idtemplate?: number,
    status?: number,
    datetosend?: number,
    timemintosend?: number,
    timemaxtosend?: number,
    idlangtpl?: number
  ) {
    this.id = id || 0;
    this.destinationtype = destinationtype || 0;
    this.priority = priority || 0;
    this.idtemplate = idtemplate || 0;
    this.status = status || Status.ACTIVE;
    this.datetosend = datetosend || 0;
    this.timemintosend = timemintosend || 0;
    this.timemaxtosend = timemaxtosend || 0;
    this.idlangtpl = idlangtpl || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "destinationtype",
        value: ["destinationtype_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },

{
        typeField: Types.FIELD_TYPE_TEXT,
        label: "datetosend",
        value: ["datetosend_name"],
      }
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL, "destinationtype"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "datetosend"),
      ComponentCommonTools.columns_StandardColumn(LL, "timemintosend"),
      ComponentCommonTools.columns_StandardColumn(LL, "timemaxtosend"),

      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();
    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<NotificationDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(NotificationDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: NotificationDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["email"])
    );
  };
}
