import { useEffect, useState, useCallback, useMemo } from "react";
import Idto from "../interfaces/idto.interface";
import ResultObjectDTO from "../dto/app/resultobject.dto";
import { useResource } from "./useResource";


type UseObjectType = <T extends Idto>(
  getObjectFunc: (id: string, cb: any, cbParams: any) => void,
  id: string,
  dependencies: Array<any>,
  useCheck?: boolean,
  useCache?: boolean,
  cacheIdentifier?: string
) => [boolean, T | null, (obj: T | null) => void];
var isFetching = false;
const useObject: UseObjectType = <T extends Idto>(
  getObjectFunc: (id: string, cb: any, cbParams: any) => void,
  id: string,
  dependencies: Array<any>,
  useCheck = true,
  useCache = false,
  cacheIdentifier = ""
) => {
  const [object, setObject] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { saveCache, getCache } = useResource();
  const getObject = useCallback(() => {
    if (useCache && cacheIdentifier) {
      const cache = getCache(cacheIdentifier);

      if (cache) {
        setObject(cache);
        return;
      }
    }
    if (isFetching && useCheck) return;

    isFetching = true;
    setLoading(true);

    getObjectFunc(id, handleGetObject, {});
  }, dependencies);

  const handleGetObject = (result: ResultObjectDTO) => {
    if (!result) return;
    const object = (result.obj as T) ?? null;

    setObject(object);
    isFetching = false;
    if (useCache && cacheIdentifier) saveCache(object, cacheIdentifier);
  };

  useEffect(() => {
    getObject();
  }, [getObject]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (object === null) loading = true;
    setLoading(loading);
  }, [object]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  
  return useMemo(() => [loading, object, setObject], [loading, object]);
};

export default useObject;
