import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class CurrencyRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("currency");
  }

  changeById = async (id: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/changecurrency/${id}`;
    return await this.getObject(url, cb, cbParams);
  };

  changeByCode = async (code: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/changecurrencycode/${code}`;
    return await this.getObject(url, cb, cbParams);
  };
}
