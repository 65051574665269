import React from "react";
import { DeliveryMethodOfferService } from "services/delivery/deliverymethodoffer.service";

import { IPage } from "interfaces/page.interface";

import { DeliveryMethodOfferDto } from "dto/delivery/deliverymethodoffer.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormDeliveryMethodOffer } from "components/special/deliverymethodoffer/FormDeliveryMethodOffer";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new DeliveryMethodOfferService();
const SpecialDeliveryMethodOfferAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  
  const onSubmit = (obj: DeliveryMethodOfferDto) => {
    if (!obj) return;
    obj.iddeliverymethod = parseInt(parentId.toString());
    
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "deliverymethodoffer",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="deliverymethodoffer"
      />
      <DataDisplayBox>
        <FormDeliveryMethodOffer
          defaultObject={
            new DeliveryMethodOfferDto()
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialDeliveryMethodOfferAdd };
