import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { OrderDto } from "dto/sale/order.dto";

type Props = {
    object: OrderDto;
  };

const ColumnPayment: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("realprice")} placement="right">
          <AttachMoneyIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "realprice"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("pricedelivery")} placement="right">
          <LocalShippingIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "pricedelivery"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("realpricetotal")} placement="right">
          <MonetizationOnIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "realpricetotal"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("pricedue")} placement="right">
          <RequestQuoteIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "pricedue"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("paymethod")} placement="right">
          <CreditCardIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "paymethod_name"])}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { ColumnPayment };
