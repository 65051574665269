import { ConfigTypes } from "tools/types/configtypes";
import {ConfigRepository} from "../../repositories/system/config.repository";
import { GeneralService } from "../general.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class ConfigService extends GeneralService<ConfigRepository> {
  constructor() {
    super(new ConfigRepository());
  }

  getHash = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getHash(this.handleGet, cbparameters);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getConfig = async (
    str: string,
    cb?: any,
    cbparameters?: any,
    type?: number
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    type = type ? type : ConfigTypes.ADMIN;
    const t = await this.repository.getConfig(
      str,
      type,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getConfigListSite = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getConfigSite(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      let sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "identifier";

      obj.sortcriteria = [sobj];
    }

    return obj;
  }
}
