import { AclRolesRepository } from "repositories/user/aclroles.repository";
import { GeneralService } from "services/general.service";

export class AclRolesService extends GeneralService<AclRolesRepository> {
  constructor() {
    super(new AclRolesRepository());
  }

  checkBulk = async (
    idrole: string,
    group?: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.checkBulk(
      idrole,
      group,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  changeBulk = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.changeBulk(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getByKeys = async (
    routename: string,
    routemethod: string,
    idrole: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getByKeys(
      routename,
      routemethod,
      idrole,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
