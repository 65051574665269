import { Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { LanguageDto } from "dto/static/language.dto";
import { useDetailObject } from "hooks/useDetailObject";
import { IPage } from "interfaces/page.interface";
import React from "react";
import {LanguageService} from "services/static/language.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
const service = new LanguageService();
const DetailLanguage: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, listUrl, object, loading } =
    useDetailObject<LanguageDto>(mainObject, currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={LanguageDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailLanguage };
