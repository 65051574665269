import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class DeliveryMethodLocationRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("deliverymethodlocation");
  }

}
