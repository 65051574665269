import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class AclRolesRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("aclroles");
  }

  checkBulk = async (
    idrole: string,
    routegroup?: string,
    cb?: any,
    cbParams?: any
  ) => {
    if (!routegroup) routegroup = "";
    let url = `/${this.mainUrl}/bulkchecks?routegroup=${routegroup}&idrole=${idrole}`;
    return await this.getObject(url, cb, cbParams);
  };

  changeBulk = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/bulkchanges`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  getByKeys = async (
    routename: string,
    routemethod: string,
    idrole: string,
    cb?: any,
    cbParams?: any
  ) => {
    let url = `/${this.mainUrl}/showbykeys?routename=${routename}&routemethod=${routemethod}&idrole=${idrole}&_ne=1`;
    return await this.getObject(url, cb, cbParams);
  };
}
