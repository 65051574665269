import SelectOptions from "dto/app/selectoptions.dto";

export class TargetType {
  public static _LL: any = false;
  public static setLL(_l: any): any {
    this._LL = _l;
  }

  public static BLANK = "_blank";
  public static SELF = "_self";
  

  public static _constants: { [key: string]: string } = {
    "_blank": "BLANK",
    "_self": "SELF",
    
  };

  // TODO: Implement this
  public static LL = (key: any) => {
    const k: number = key;
    if (!this._constants.hasOwnProperty(k)) return "";
    return this._LL("TargetType_" + this._constants[k].toString());
  };

  public static GA_KEYS = (key: string): Array<string> => {
    let rez: Array<string> = [];

    if (key === "general") {
      rez = [
        this.BLANK,
        this.SELF,
      ];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyValue?: boolean,
    emptyLabel?: string
  ) => {
    const keys = this.GA_KEYS(key);
    const rez: Array<SelectOptions> = [];
    if (emptyValue) {
      const empty = new SelectOptions();
      empty.value = "";
      empty.label = emptyLabel ?? TargetType._LL("all");
      rez.push(empty);
    }
    keys.forEach((k) => {
      const so = new SelectOptions();
      so.value = k;
      so.label = TargetType.LL(k);
      rez.push(so);
    });

    return rez;
  };
}
