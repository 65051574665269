import { useState, useEffect, useMemo } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { useGetUrlsList } from "./useGetUrlsList";
import Idto from "interfaces/idto.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { useCookies } from "react-cookie";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { logger } from "tools/utils/logger";

type HasId = Idto & {
  id?: string | number;
};

type UseListAdmin = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  useDefaultApproach?: boolean
) => {
  mainUrl: string;
  addUrl: string;
  setListUrl: (url: string) => void;
  data: ResultListDTO;
  rows: Array<T>;
  total: number;
  totalPage: number;
  responseParams: RequestListDTO;
  reqList: RequestListDTO | null;
  loading: boolean;
  deleteOneObject: any;
  setDeleteOneObject: (obj: T | undefined) => void;
  handleDelete: (item?: T) => void;
  setReqList: (req: RequestListDTO) => void;
  getList: () => void;
  listUrl: string;
};
var isRequesting = false;
const useListAdmin: UseListAdmin = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  useDefaultApproach?: boolean
) => {
  if (useDefaultApproach === undefined) useDefaultApproach = true;

  const [cookies] = useCookies();
  const { mainUrl, addUrl, setListUrl, listUrl } = useGetUrlsList(
    mainObject,
    currentRoute,
    useDefaultApproach
  );

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<T>>(new Array<T>());
  const [total, setTotal] = useState(-1);
  const [totalPage, setTotalPage] = useState(-1);
  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );
  const [reqList, setReqList] = useState<RequestListDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<T | undefined>(
    undefined
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
    isRequesting = false;
  };

  const getList = () => {
    if (!reqList) return;
    if (isRequesting) return;
    isRequesting = true;
    setLoading(true);
    if (useDefaultApproach) {
      const urlPart = RouteTools.prepareListLocation(reqList);
      RouteTools.setHistory(mainUrl + urlPart, {});
      setListUrl(mainUrl + urlPart);
    }
    getListFun(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    let reqListLocal = RouteTools.prepareListRequest(currentRoute, cookies);
    reqListLocal = getRequestListFun(reqListLocal);
    setReqList(reqListLocal);
  };

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  useEffect(() => {
    getReqList();
  }, []);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const handleDelete = (item?: T) => {
    if (!item) return;
    if (!item.hasOwnProperty("id")) return;
    if (!item.id) return;
    deleteFun(CommonTools.processObjectField(item, ["id"]), getList, {});
  };

  return useMemo(
    () => ({
      mainUrl,
      addUrl,
      setListUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      setDeleteOneObject,
      handleDelete,
      setReqList,
      getList,
      listUrl,
    }),
    [
      mainUrl,
      addUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      listUrl,
    ]
  );
};

export { useListAdmin };
