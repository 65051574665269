interface Filter {
  key: string;
  label: string;
  type: string;
  exist: boolean;
  value: string;
  values: string[];
  showedvalue: string;
}

class FilterTools {
  static fiterTypeText: number = 1;
  static fiterTypeSelect: number = 2;
  static fiterTypeSelectMultiple: number = 3;
  static fiterTypeRange: number = 4;
  static fiterTypeDate: number = 5;
  static fiterTypeDateRange: number = 6;
  static fiterTypeDateTS: number = 7;
  static fiterTypeDateTSRange: number = 8;

  static getFiltersUrlPart(filters: Filter[]) {
    let str = "";
    let exist: string[] = [];

    for (let i in filters) {
      if (exist.includes(filters[i].key)) continue;
      if (!Array.isArray(filters[i].values)) continue;
      if (!filters[i].values.length) continue;

      let tf = filters[i].values.filter((n) => n);
      if (!tf.length) continue;

      exist.push(filters[i].key);

      let tstr = "";
      tstr += filters[i].key;
      for (let j in tf) {
        tstr += ",";
        tstr += encodeURIComponent(tf[j]);
      }

      str += str ? "|" : "";
      str += tstr;
    }

    return str;
  }

  static getFiltersUrl(filters: Filter[], suffix?: string) {
    suffix = suffix !== undefined ? suffix : "";
    if (!Array.isArray(filters)) return suffix;

    let str = FilterTools.getFiltersUrlPart(filters);
    if (!str) return suffix;

    suffix += suffix ? "&" : "?";
    suffix += "filters=" + str;

    return suffix;
  }

  static parseFiltersUrl(values: string) {
    if (values === undefined) return {};
    if (!values) return {};

    let tvals = values.split("|");
    let rez: { [key: string]: string[] } = {};
    for (let i in tvals) {
      if (!tvals[i]) continue;
      let tf = tvals[i].split(",");
      tf = tf.filter((n) => n);

      let key = tf.shift();
      if (key !== undefined) {
        rez[key] = [];
        for (let j in tf) {
          rez[key].push(decodeURIComponent(tf[j]));
        }
      }
    }

    return rez;
  }

  static parseFiltersObject_TextValue(
    LL: any,
    tfilters: any,
    key: string,
    label?: string,
    type?: string
  ) {
    label = label !== undefined ? label : LL("FilterLabel_" + key);

    let _tfobj: Filter = {
      key: key,
      label: label!,
      type: type !== undefined ? type : "textvalue",
      exist: false,
      value: "",
      values: [],
      showedvalue: "",
    };

    if (tfilters[key] !== undefined) {
      if (Array.isArray(tfilters[key])) {
        _tfobj.values = tfilters[key];
        if (tfilters[key].length) {
          _tfobj.value = tfilters[key][0];
          _tfobj.exist = true;
        }
      } else {
        _tfobj.values.push(tfilters[key]);
        _tfobj.value = tfilters[key];
        _tfobj.exist = true;
      }
    }

    _tfobj.showedvalue = _tfobj.value;

    return _tfobj;
  }
}

export { FilterTools };
