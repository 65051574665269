import { Status } from "tools/types/status";
import { FileDto } from "./file.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";

export class GalleryDto {
  id?: number;
  parent?: string;
  id_parent?: number;
  order?: number;
  id_file?: number;
  isdefault?: number;
  status?: number;
  _idlang?: number;
  _name?: string;
  _fileObj?: FileDto | null;
  fullurl?: string;
  downloadurl?: string;
  cdnurl?: string;
  file: File | null;

  constructor(
    _idlang: number,
    parent?: string,
    id_parent?: number,
    id?: number,
    order?: number,
    id_file?: number,
    isdefault?: number,
    status?: number,
    _name?: string,
    _fileObj?: FileDto,
    fullurl?: string,
    downloadurl?: string,
    cdnurl?: string
  ) {
    this._idlang = _idlang;
    this.id = id ?? 0;
    this.parent = parent ?? "";
    this.id_parent = id_parent ?? 0;
    this.order = order ?? 0;
    this.id_file = id_file ?? 0;
    this.isdefault = isdefault ?? 0;
    this.status = status ?? Status.ACTIVE;
    this._name = _name ?? "";
    this._fileObj = _fileObj ?? null;
    this.fullurl = fullurl ?? "";
    this.downloadurl = downloadurl ?? "";
    this.cdnurl = cdnurl ?? "";
    this.file = null;
  }

  static parseForEdit(obj: any): GalleryDto {
    return new GalleryDto(
      obj._idlang,
      obj.parent,
      obj.id_parent,
      obj.id,
      obj.order,
      obj.id_file,
      obj.isdefault,
      obj.status,
      obj._name,
      obj._fileObj,
      obj.fullurl,
      obj.downloadurl,
      obj.cdnurl
    );
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "isdefault",
        value: ["isdefault_name"],
      },
      {
        typeField: Types.FIELD_TYPE_IMAGE,
        label: "image",
        value: ["cdnurl"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "isdefault"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_ImageColumn(LL, "cdnurl"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(
        LL,
        "isdefault",
        undefined,
        true,
        true,
        "boolean"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "gallery"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
