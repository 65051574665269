import React from "react";
import { GalleryService } from "services/system/gallery.service";

import { IPage } from "interfaces/page.interface";

import { GalleryDto } from "dto/system/gallery.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormGallery } from "components/special/gallery/FormGallery";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";
import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new GalleryService();
const SpecialGalleryAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: GalleryDto) => {
    if (!obj) return;
    obj.id_parent = parseInt(parentId.toString());
    obj.parent = parentType;
    setLoading(true);
    service.addMultipart(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "gallery",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="gallery"
      />
      <DataDisplayBox>
        <FormGallery
          defaultObject={
            new GalleryDto(parseInt(idSelectedLanguage.toString()))
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialGalleryAdd };
