import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import { ServiceTools } from "tools/utils/servicetools";
import { useLabel } from "hooks/useLabel";

type Props = {
  parentType: string;
  parentId: string;
  specialtype: string;
  suplimentar?: string;
};

const SpecialTopInfo: React.FC<Props> = ({
  parentType,
  parentId,
  specialtype,
  suplimentar,
}) => {
  const { LL } = useLabel();
  suplimentar = suplimentar ?? "";

  const [parentName, setParentName] = useState<string>("");

  const setParentInfo = async () => {
    const t = await ServiceTools.getObjectName(parentId, parentType);
    setParentName(t);
  };

  useEffect(() => {
    if(!parentType || !parentId) return;
    setParentInfo();
  }, [parentType, parentId]);

  return (
    <Typography variant="h4" sx={{ my: 3 }}>
      {LL(suplimentar + specialtype + "page")} {LL("SPT from")} {parentName}
    </Typography>
  );
};

export { SpecialTopInfo };
