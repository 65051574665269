import { GroupedAclDto } from "dto/system/route.dto";
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CommonTools } from "tools/utils/common.tool";
import { AclRoleList } from "./AclRoleList";

type Props = {
  group: GroupedAclDto;
  openDefault: boolean;
  idRole: string;
};

const GroupAclItem: React.FC<Props> = ({ group, openDefault, idRole }) => {
  const [open, setOpen] = useState(openDefault);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleToggle}
      id={CommonTools.processObjectField(group, ["routegroup"])}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="group-acl-content"
        id="group-acl-header">
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "14px",
          }}>
          {CommonTools.processObjectField(group, ["routegroup"])}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {open ? (
          <AclRoleList
            objects={group.routes}
            idRole={idRole}
            group={group.routegroup}
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export { GroupAclItem };
