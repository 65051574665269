import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { SelectRole } from "components/user/role/SelectRole";

import { UserRoleDto } from "dto/user/userrole.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";


import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: UserRoleDto;
  onSubmit: (data: UserRoleDto) => void;
  loading: boolean;
};
const FormUserRole: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField] = useForm<UserRoleDto>(
    defaultObject,
    RequiredValidator.getValidators(["idrole"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectRole value={obj.idrole} setObjectField={setObjField} />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormUserRole };
