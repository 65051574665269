import React from "react";
import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
  value: number | string;
  index: number | string;
};

const TabPanel: React.FC<Props> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export { TabPanel };
