import RequestListDTO from "dto/app/requestlist.dto";
import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class AclRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("acl");
  }

  getRoutes = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `/${this.mainUrl}/getroutes`;
    return await this.getListObject(url, cb, cbParams, data);
  };

  getAccessRoutes = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `/${this.mainUrl}/getaccessroutes`;
    return await this.getListObject(url, cb, cbParams, data);
  }

  getHash = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/hash`;
    return await this.getObject(url, cb, cbParams);
  };

}
