import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DetailGridItem } from "./DetailGridItem";
import { Link } from "@mui/material";

const DetailGridFile: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  if (!CommonTools.processObjectField(object, item.value)) return null;
  const options = item.options ? item.options : null;
  if(!options) return null;
  const fields = options.fields ? options.fields : null;
  if(!fields) return null;
  return (
    <DetailGridItem item={item} index={index}>
      <Link
        href={CommonTools.processObjectField(object, item.value)}
        target="_blank"
        rel="noreferrer"
      >
        {CommonTools.processObjectField(object, fields)}
      </Link>
    </DetailGridItem>
  );
};

export { DetailGridFile };
