import { Skeleton } from "@mui/material";
import { MySelect } from "components/elements/select/MySelect";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { FilterDto } from "dto/product/filter.dto";
import { useList } from "hooks/useList";

import React, { useEffect } from "react";
import { FilterService } from "services/product/filter.service";
import { Status } from "tools/types/status";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  setType: (type: number) => void;
};
const service = new FilterService();
const SelectFilterAndType: React.FC<Props> = ({
  field = "idfilter",
  value,
  setObjectField,
  setType,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };

  const [loading, objects] = useList<FilterDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1
    ),
    []
  );

  useEffect(() => {
    if (loading) return;
    if (!objects) return;
    setType(FilterDto.getType(objects, value as number));
  }, [objects, loading, value]);

  if (loading) return <Skeleton variant="rectangular" height={50} />;
  if (!objects) return null;
  return (
    <MySelect
      options={FilterDto.parseToSelectOptions(objects)}
      _label={"field_filter"}
      setObjectField={setObjectField}
      value={value}
      field={field}
    />
  );
};

export { SelectFilterAndType };
