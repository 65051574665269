import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";

import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ArticleIcon from "@mui/icons-material/Article";
import HomeIcon from "@mui/icons-material/Home";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { ClientType } from "tools/types/clienttype";
import { OrderDto } from "dto/sale/order.dto";

type Props = {
    object: OrderDto;
  };

const ColumnInvoice: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {parseInt(
        CommonTools.processObjectField(object, [ "client_type"])
      ) === ClientType.INDIVIDUAL ? (
        <Box>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_fiz_name")} placement="right">
              <PersonIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_fiz_name",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_fiz_idno")} placement="right">
              <AccountBoxIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_fiz_idno",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_fiz_docnumber")} placement="right">
              <ArticleIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_fiz_docnumber",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_fiz_address")} placement="right">
              <HomeIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_fiz_address",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_fiz_phone")} placement="right">
              <ContactPhoneIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_fiz_phone",
              ])}
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_jur_company")} placement="right">
              <BusinessIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_jur_company",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_jur_idno")} placement="right">
              <WorkIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_jur_idno",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_jur_iban")} placement="right">
              <AccountBalanceWalletIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_jur_iban",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_jur_bank")} placement="right">
              <AccountBalanceIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_jur_bank",
              ])}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Tooltip title={LL("client_fct_jur_phone")} placement="right">
              <ContactPhoneIcon />
            </Tooltip>
            <Typography>
              {CommonTools.processObjectField(object, [
                
                "client_fct_jur_phone",
              ])}
            </Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export { ColumnInvoice };
