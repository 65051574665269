import { Box } from "@mui/material";

import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { AutocompleteFAQ } from "components/static/faq/AutocompleteFAQ";

import { FAQRelationDto } from "dto/static/faqrelation.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

type Props = {
  defaultObject: FAQRelationDto;
  onSubmit: (data: FAQRelationDto) => void;
  loading: boolean;
};

const FormFAQRelation: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,,vResults] = useForm<FAQRelationDto>(
    defaultObject,
    []
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <AutocompleteFAQ setObjField={setObjField} defaultObject={obj._faqObj} value={obj.idfaq}/>
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormFAQRelation };
