import React from "react";
import { GeneralFilterSelect } from "./GeneralFilterSelect";
import SelectOptions from "dto/app/selectoptions.dto";

type Props = {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
};

const PageSelect: React.FC<Props> = ({ page, setPage, totalPages }) => {
  const objects = Array.from({ length: totalPages }, (_, i) => i + 1);
  const options: SelectOptions[] = objects.map((item) => {
    return { value: item, label: item.toString() };
  });
  if (!totalPages || totalPages < 0) return null;
  return (
    <GeneralFilterSelect
      options={options}
      value={page}
      setValue={setPage}
      _label="page"
      size="small"
    />
  );
};

export { PageSelect };
