import React from "react";
import { MySelect } from "./MySelect";
import { Status } from "tools/types/status";

type Props = {
  typeStatus?: string;
  label?: string;
  setObjField: (field: string, value: any) => void;
  value: string | number | undefined;
  field?: string;
};
const StatusSelect: React.FC<Props> = ({
  typeStatus = "clasificator",
  label = "select_status",
  setObjField,
  value,
  field = "status",
}) => {
  return (
    <MySelect
      options={Status.GA(typeStatus)}
      _label={label}
      setObjectField={setObjField}
      value={value}
      field={field}
      
    />
  );
};

export { StatusSelect };
