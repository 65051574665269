import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LabelDto } from "dto/static/label.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: LabelDto;
  onSubmit: (data: LabelDto) => void;
  loading: boolean;
};
const FormLabel: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,,vResults] = useForm<LabelDto>(
    defaultObject,
    RequiredValidator.getValidators(["_name"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <StatusSelect
            setObjField={setObjField}
            value={obj.status}
            typeStatus="status"
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormLabel };
