import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyDatePickerTimestamp } from "components/elements/datepicker/MyDatePickerTimestamp";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { DeliveryMethodOfferDto } from "dto/delivery/deliverymethodoffer.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: DeliveryMethodOfferDto;
  onSubmit: (data: DeliveryMethodOfferDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormDeliveryMethodOffer: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , , setObject,vResults] = useForm<DeliveryMethodOfferDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "real_price",
      "price",
      "discount_percent",
      "discount_value",
    ])
  );

  const setValues = (
    discountPercent: number | string,
    discountValue: number | string,
    realPrice: number | string,
    price: number | string
  ) => {
    const newObj = JSON.parse(JSON.stringify(obj));

    if (typeof discountPercent === "number")
      newObj.discount_percent = discountPercent.toFixed(2);
    else newObj.discount_percent = discountPercent;
    if (typeof discountValue === "number")
      newObj.discount_value = discountValue.toFixed(2);
    else newObj.discount_value = discountValue;
    if (typeof realPrice === "number") newObj.real_price = realPrice.toFixed(2);
    else newObj.real_price = realPrice;
    if (typeof price === "number") newObj.price = price.toFixed(2);
    else newObj.price = price;

    setObject(newObj);
  };

  const setObjectFieldPrice = (field: string, value: any) => {
    if (value === "") {
      setObjField(field, value);
      return;
    }
    const price = value;
    let real_price = value;
    const discount_percent = obj.discount_percent || 0;
    let discount_value = obj.discount_value || 0;
    if (!discount_percent) real_price = price;
    else {
      real_price = DeliveryMethodOfferDto.calculateRealPrice(
        price,
        discount_percent as number
      );
      discount_value = DeliveryMethodOfferDto.calculateDiscountValue(
        price,
        discount_percent as number
      );
    }
    setValues(discount_percent, discount_value, real_price, price);
  };

  const setObjectFieldRealPrice = (field: string, value: any) => {
    if (value === "") {
      setObjField(field, value);
      return;
    }
    let real_price = value;
    let price = value;
    const discount_percent = obj.discount_percent || 0;
    let discount_value = obj.discount_value || 0;
    if (!discount_percent) price = real_price;
    else {
      price = DeliveryMethodOfferDto.calculatePrice(real_price, discount_percent as number);
      discount_value = DeliveryMethodOfferDto.calculateDiscountValue(
        price,
        discount_percent as number
      );
    }

    setValues(discount_percent, discount_value, real_price, price);
  };

  const setObjectFieldDiscountPercent = (field: string, value: any) => {
    if (value === "") {
      setObjField(field, value);
      return;
    }
    if (value < 0) value = 0;
    const discount_percent = value;
    let price = obj.price || 0;
    const real_price = DeliveryMethodOfferDto.calculateRealPrice(
      price as number,
      discount_percent
    );
    const discount_value = DeliveryMethodOfferDto.calculateDiscountValue(
      price as number,
      discount_percent
    );

    setValues(discount_percent, discount_value, real_price, price);
  };

  const setObjectFieldDiscountValue = (field: string, value: any) => {
    if (value === "") {
      setObjField(field, value);
      return;
    }
    const discount_value = value;

    const price = obj.price || 0;
    const discount_percent = DeliveryMethodOfferDto.calculateDiscountPercent(
      price as number,
      discount_value
    );

    const real_price = DeliveryMethodOfferDto.calculateRealPrice(
      price as number,
      discount_percent
    );

    setValues(discount_percent, discount_value, real_price, price);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="priority"
            label={LL("field_priority")}
            setObjectField={setObjField}
            value={obj.priority}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="mincartvalue"
            label={LL("field_mincartvalue")}
            setObjectField={setObjField}
            value={obj.mincartvalue}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="maxcartvalue"
            label={LL("field_maxcartvalue")}
            setObjectField={setObjField}
            value={obj.maxcartvalue}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="price"
            label={LL("field_price")}
            setObjectField={setObjectFieldPrice}
            value={obj.price}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="discount_percent"
            label={LL("field_discount_percent")}
            setObjectField={setObjectFieldDiscountPercent}
            value={obj.discount_percent}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="discount_value"
            label={LL("field_discount_value")}
            setObjectField={setObjectFieldDiscountValue}
            value={obj.discount_value}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="real_price"
            label={LL("field_real_price")}
            setObjectField={setObjectFieldRealPrice}
            value={obj.real_price}
            type={"number"}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyDatePickerTimestamp
            field="startdate"
            setObjField={setObjField}
            value={obj.startdate}
            time={{ hour: 0, minute: 0 }}
            
          />
        </Box>
        <Box mt={3}>
          <MyDatePickerTimestamp
            field="enddate"
            setObjField={setObjField}
            value={obj.enddate}
            time={{ hour: 23, minute: 59 }}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormDeliveryMethodOffer };
