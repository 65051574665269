import React from "react";

import { IPage } from "interfaces/page.interface";
import { CountryService } from "services/static/country.service";
import { CountryDto } from "dto/static/country.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormCountry } from "components/static/country/FormCountry";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
// import { Box, Tabs, Tab } from "@mui/material";
// import { useLabel } from "hooks/useLabel";
// import { TabPanel } from "components/elements/display/TabPanel";
// import { AddCountryLang } from "components/countrylang/AddCountryLang";
// import { AddCountryCurrency } from "components/countrycurrency/AddCountryCurrency";
const service = new CountryService();
const EditCountry: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };
  // const { LL } = useLabel();
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    // handleChangeTab,
    // tabValue,
  } = useEditObject<CountryDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: CountryDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        // anchor: tabValue,
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        // needUpdate={true}
        // anchorTab={tabValue}
        // defaultTab="generalinfo"
      />
      {/* <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_Country_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Edit_language")} value={"language"} />
          <Tab label={LL("Edit_currency")} value={"currency"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}> */}
        <DataDisplayBox>
          <FormCountry
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </DataDisplayBox>
      {/* </TabPanel>
      <TabPanel value={tabValue} index={"language"}>
        <DataDisplayBox>
          <AddCountryLang
            idCountry={id}
            currentRoute={currentRoute}
            mainObject={mainObject}
          />
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"currency"}>
        <DataDisplayBox>
          <AddCountryCurrency
            idCountry={id}
            currentRoute={currentRoute}
            mainObject={mainObject}
          />
        </DataDisplayBox>
      </TabPanel> */}
    </React.Fragment>
  );
};

export { EditCountry };
