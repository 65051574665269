import React, { createContext, useState, useEffect } from "react";
import { Md5 } from "ts-md5";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";

import { useUser } from "hooks/useUser";
import { useLanguage } from "./LanguageProvider";
import { ServiceTools } from "tools/utils/servicetools";
import { useCookies } from "react-cookie";
import { Config } from "tools/utils/config";
import { DateTools } from "tools/utils/date.tool";
import { SessionStorageTools } from "api/sessionstorage.api";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  currentRoute: ICurrentRoute | null;
  setCurrentRoute: (value: ICurrentRoute | null) => void;
  saveCache: (data: any, key: string) => void;
  getCache: (key: string) => any;
  _getListUrl: (defaultUrl?: string) => string;
  _setListUrl: (value: string) => void;
  setCookieStayOnPage: (mainObject: string, value: boolean) => void;
  getCookieStayOnPage: (mainObject: string) => boolean;
  triggerAclRoles: string;
  handleTriggerAclRoles: () => void;
  triggerAclRolesGlobal: string;
  handleTriggerAclRolesGlobal: () => void;

};
export const ResourceContext = createContext<Props>({
  currentRoute: null,
  setCurrentRoute: () => {},
  saveCache: () => {},
  getCache: () => {},
  _getListUrl: () => "",
  _setListUrl: () => {},
  setCookieStayOnPage: () => {},
  getCookieStayOnPage: () => false,
  triggerAclRoles: "",
  handleTriggerAclRoles: () => {},
  triggerAclRolesGlobal: "",
  handleTriggerAclRolesGlobal: () => {},
});
const cacheData: any = {};

export const ResourceProvider: React.FC<IProvider> = ({ children }) => {
  
  const getDefaultListUrl = (): string => {
    const listUrl = SessionStorageTools.getValue("list_url");
    if (listUrl) return listUrl;
    else return "";
  };
  
  const [listUrl, setListUrl] = useState(getDefaultListUrl());
  const [currentRoute, setCurrentRoute] = useState<ICurrentRoute | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const { selectedLanguageCode } = useLanguage();
  const [cookies, setCookie, removeCookie] = useCookies();

  const [triggerAclRoles, setTriggerAclRoles] = useState<string>("");
  const handleTriggerAclRoles = () => {
    setTriggerAclRoles(CommonTools.generateRandomString(10))
  }
  const [triggerAclRolesGlobal, setTriggerAclRolesGlobal] = useState<string>("");
  const handleTriggerAclRolesGlobal = () => {
    setTriggerAclRolesGlobal(CommonTools.generateRandomString(10))
  }
  const setCookieStayOnPage = (mainObject: string, value: boolean) => {
    if (value) {
      setCookie(mainObject + "_stayonpage", true, {
        path: "/",
        expires: DateTools.getDate(Config.COOKIE_EXPIRES),
      });
    } else {
      removeCookie(mainObject + "_stayonpage", {
        path: "/",
      });
    }
  };

  const getCookieStayOnPage = (mainObject: string) => {
    const value = cookies[mainObject + "_stayonpage"] ?? false;
    return value;
  };

  useEffect(() => {
    ServiceTools.setSaveCache(saveCache);
    ServiceTools.setGetCache(getCache);
    setLoading(false);
  }, []);

  const getCacheIdentifier = (key: string) => {
    let k = selectedLanguageCode;
    k += "_" + JSON.stringify(user);
    k += "_" + JSON.stringify(key);

    return Md5.hashStr(k);
  };

  const saveCache = (data: any, key: string) => {
    const identifier = getCacheIdentifier(key);

    cacheData[identifier] = data;
  };

  const getCache = (key: string) => {
    const identifier = getCacheIdentifier(key);

    if (cacheData[identifier] !== undefined) return cacheData[identifier];

    return false;
  };

  const _getListUrl = (_default?: string) => {
    _default = _default ?? "";
    let rez = listUrl ?? "";
    rez = rez !== undefined && rez !== "" ? rez : _default;
    return rez;
  };
  const _setListUrl = (value: string) => {
    setListUrl(value);
    SessionStorageTools.saveValue("list_url", value);
  };

  const value = {
    currentRoute,
    setCurrentRoute,
    saveCache,
    getCache,
    _getListUrl,
    _setListUrl,
    setCookieStayOnPage,
    getCookieStayOnPage,
    triggerAclRoles,
    handleTriggerAclRoles,
    triggerAclRolesGlobal,
    handleTriggerAclRolesGlobal,
  };

  return loading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
