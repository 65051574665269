import { SetActionDto } from "dto/sale/order.dto";
import { OrderRepository } from "repositories/sale/order.repository";
import { GeneralService } from "services/general.service";

export class OrderService extends GeneralService<OrderRepository> {
  constructor() {
    super(new OrderRepository());
  }

  setStatusNew = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusNew(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusVerified = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusVerified(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusInProcess = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusInProcess(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusProcessed = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusProcessed(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusOnCourier = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusOnCourier(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusDelivered = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusDelivered(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusArchived = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusArchived(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusCancelled = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusCancelled(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setStatusReturn = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setStatusReturn(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setPayStatusPaid = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setPayStatusPaid(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setPayStatusNeedReturn = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setPayStatusNeedReturn(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setPayStatusCanceled = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setPayStatusCanceled(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  setPayStatusReturned = async (
    cb?: any,
    cbparameters?: any,
    data?: SetActionDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.setPayStatusReturned(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
