import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { PasswordField } from "components/elements/form/PasswordField";
import { SignupDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  onSubmit: (obj: SignupDto, cb: CallbackType) => void;
};
const SignUpForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const [obj, disabled, setObjField,,,,vResults] = useForm<SignupDto>(
    new SignupDto(),
    RequiredValidator.getValidators(["email", "password"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">{LL("signup_title")}</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3} sx={{ width: "100%" }}>
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            vResults={vResults}
          />
        </Box>
        <Box mt={3} sx={{ width: "100%" }}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            value={obj.password}
            sx={{ width: "100%" }}
            vResults={vResults}
          />
        </Box>
        <Box mt={3} sx={{ width: "100%" }}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
            sx={{ width: "100%", height: "45px" }}
          >
            {LL("btn_signup")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { SignUpForm };
