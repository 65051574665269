import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

type Props = TextFieldProps & {
  field: string;
  label: string;
  obj: any;
  setObj: (obj: any) => void;
};

const MyFileField: React.FC<Props> = ({
  label,
  field,
  obj,
  setObj,
  ...props
}) => {
  const handleChange = (event: any) => {
    if (!obj) return;
    if (!obj.hasOwnProperty(field)) return;
    const newObj = { ...obj };
    newObj[field] = event.target.files[0];
    setObj(newObj);
  };

  return (
    <TextField
      label={label}
      onFocus={handleChange}
      onChange={handleChange}
      type="file"
      sx={{ width: "100%", maxWidth: "400px" }}
      variant="outlined"
      {...props}
    />
  );
};

export { MyFileField };
