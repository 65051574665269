import { Box } from "@mui/material";
import { MyTextField } from "components/elements/form/MyTextField";
import { UserDto } from "dto/user/user.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";

type Props = {
  obj: UserDto;
  setObjField: (field: string, value: any) => void;
};

const FormUserAdd: React.FC<Props> = ({ obj, setObjField }) => {
  const { LL } = useLabel();

  return (
    <React.Fragment>
      <Box>
        <MyTextField
          field="email"
          label={LL("field_email")}
          setObjectField={setObjField}
          value={obj.email}
        />
      </Box>
      <Box mt={3}>
        <MyTextField
          field="password"
          label={LL("field_password")}
          setObjectField={setObjField}
          value={obj.password}
          type="password"
        />
      </Box>
    </React.Fragment>
  );
};

export { FormUserAdd };
