import { Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { OrderJournalDto } from "dto/sale/orderjournal.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { OrderJournalService } from "services/sale/orderjournal.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { useDetailSpecial } from "hooks/useDetailSpecial";
import { HeaderDetailSpecial } from "components/elements/detail/HeaderDetailSpecial";

const service = new OrderJournalService();
const SpecialOrderJournalDetail: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, parentType, parentId, object, loading,mainListUrl } =
    useDetailSpecial<OrderJournalDto>("orderjournal", currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecial
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        specialType="orderjournal"
        mainListUrl={mainListUrl}
        showEditBtn={false}
        
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={OrderJournalDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialOrderJournalDetail };
