import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class DeliveryMethodOfferRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("deliverymethodoffer");
  }

}
