import Idto from "interfaces/idto.interface";

export class SendTestEmailDto implements Idto {
  toemail: string;
  idfromemail: number;

  cc: string;
  bcc: string;
  _subject: string;
  _message: string;

  constructor(
    idfromemail?: number,
    toemail?: string,
    cc?: string,
    bcc?: string,
    _subject?: string,
    _message?: string
  ) {
    this.idfromemail = idfromemail || 0;
    this.toemail = toemail || "";
    this.cc = cc || "";
    this.bcc = bcc || "";
    this._subject = _subject || "";
    this._message = _message || "";
  }
}
