import Idto from "interfaces/idto.interface";


export class AclRolesDto implements Idto {
  id?: number | string;
  routename?: string;
  routemethod?: string;
  idrole?: number | string;
  value?: number;

  constructor(
    id?: number | string,
    routename?: string,
    routemethod?: string,
    idrole?: number | string,
    value?: number
  ) {
    this.id = id || 0;
    this.routename = routename || "";
    this.routemethod = routemethod || "";
    this.idrole = idrole || 0;
    this.value = value || 0;
  }

  

}

