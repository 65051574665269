import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Types } from "tools/types/types";

export class OfferFilterDto implements Idto {
  id?: number | string;
  idoffer?: number;
  idfilter?: number;
  idfilterdictionar?: number;

  constructor(
    id?: number | string,
    idoffer?: number,
    idfilter?: number,
    idfilterdictionar?: number
  ) {
    this.id = id || 0;
    this.idoffer = idoffer || 0;
    this.idfilter = idfilter || 0;
    this.idfilterdictionar = idfilterdictionar || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "filter",
        value: ["filterObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "dilterdictionar",
        value: ["filterDictionarObj", "_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterObj",
        "_name"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterDictionarObj",
        "_name"
      ),

      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterObj",
        "_name"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterDictionarObj",
        "_name"
      ),

      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "offerfilter"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    return obj;
  }

  static parseToSelectOptions(
    data: Array<OfferFilterDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(OfferFilterDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: OfferFilterDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["filtervalue"])
    );
  };

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idoffer";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idoffer") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
