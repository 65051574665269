import { ListItemButton, Typography } from "@mui/material";
import { GroupedAclDto } from "dto/system/route.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: GroupedAclDto;
};

const MenuAclItem: React.FC<Props> = ({ object }) => {
  const navigateToSection = () => {
    const element = document.getElementById(
      CommonTools.processObjectField(object, ["routegroup"])
    );

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };
  return (
    <ListItemButton onClick={navigateToSection}>
      <Typography>
        {CommonTools.processObjectField(object, ["routegroup"])}
      </Typography>
    </ListItemButton>
  );
};

export { MenuAclItem };
