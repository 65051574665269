import React from "react";
import { LeftPartUserMenu } from "./LeftPartUserMenu";
import { Box } from "@mui/material";

type Props = {
  setOpen: (open: boolean) => void;
};

const LeftPart: React.FC<Props> = ({ setOpen }) => {
  return (
    <Box>
      <LeftPartUserMenu setOpen={setOpen} />
    </Box>
  );
};

export { LeftPart };
