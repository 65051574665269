import React from "react";
import { Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { DetailGridItem } from "./DetailGridItem";
import { DetailGridItemSimpleProps } from "interfaces/detailobject.interface";

const DataGridNoData: React.FC<DetailGridItemSimpleProps> = ({
  item,
  index,
}) => {
  const { LL } = useLabel();

  return (
    <DetailGridItem item={item} index={index}>
      <Typography>{LL("No_data_yet")}</Typography>
    </DetailGridItem>
  );
};

export { DataGridNoData };
