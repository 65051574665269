import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { NomenclatureDto } from "dto/nomenclature/nomenclature.dto";
import { CountryDto } from "./country.dto";
import { GeneralService } from "services/general.service";

export class LocationDto implements Idto {
  id?: number | string;
  identifier?: string;

  order?: number;
  status?: number;

  hierarchicalcode?: string;
  level?: number;
  idparent?: string;

  _idlang?: number | string;
  _name?: string;
  idtypelocation?: number;
  idcountry?: number;

  typeLocationObj?: NomenclatureDto;
  countryObj?: CountryDto;

  constructor(
    _idlang?: number | string,
    idparent?: string,
    id?: number | string,
    identifier?: string,
    order?: number,
    status?: number,
    _name?: string,
    idtypelocation?: number,
    idcountry?: number
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";

    this.idparent = idparent || "";
    this.idtypelocation = idtypelocation || 0;
    this.idcountry = idcountry || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typelocation",
        value: ["typeLocationObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "country",
        value: ["countryObj", "nameofficial"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "children"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<LocationDto>,
    all?: boolean
  ): Array<SelectOptions> {
    if (all === undefined) all = false;
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    if (all) result.push(new SelectOptions(-1, GeneralService.LL("All")));
    data.forEach((element) => {
      result.push(LocationDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: LocationDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };
}
