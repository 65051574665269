import GeneralRepository from "repositories/general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class ProductWishlistRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("productwishlist");
  }
}
