import {  Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { OrderDto } from "dto/sale/order.dto";


type Props = {
    object: OrderDto;
  };

const ColumnDelivery: React.FC<Props> = ({ object }) => {
    const {LL} = useLabel();
    if(!object) return <></>;
    return (
        <Stack
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("country")} placement="right">
                  <LanguageIcon />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [
                    
                    "countryObj",
                    "nameofficial",
                  ])}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("location")} placement="right">
                  <LocationOnIcon />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [
                    
                    "locationObj",
                    "_name",
                  ])}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("address")} placement="right">
                  <HomeIcon />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [
                    
                    "destinatar_address",
                  ])}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("deliverymethod")} placement="right">
                  <LocalShippingIcon />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [
                    
                    "deliveryMethodObj",
                    "_name",
                  ])}
                </Typography>
              </Stack>
            </Stack>
    )
}

export { ColumnDelivery }