import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { MyCheckboxField } from "../form/MyCheckboxField";
import { useLabel } from "hooks/useLabel";

type Props = {
  mainObject?: string;
};

const StayOnPageCheckbox: React.FC<Props> = ({ mainObject }) => {
  const { setCookieStayOnPage, getCookieStayOnPage } = useResource();
  const stayOnPage = getCookieStayOnPage(mainObject || "");

  const [checked, setChecked] = useState(stayOnPage);
  const { LL } = useLabel();
  const setValue = (field: string, value: boolean) => {
    if (!mainObject) return;
    setChecked(value);
    setCookieStayOnPage(mainObject, value);
  };
  if (!mainObject) return null;
  return (
    <MyCheckboxField
      label={LL("stay_on_page")}
      field={"stayonpage"}
      setObjectField={setValue}
      checked={checked}
    />
  );
};

export { StayOnPageCheckbox };
