import { GeneralAutocomplete } from "components/elements/autocomplete/GeneralAutocomplete";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { FAQDto } from "dto/static/faq.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { FAQService } from "services/static/faq.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: FAQDto | null;
  value?: string | number;
};

const service = new FAQService();
const AutocompleteFAQ: React.FC<Props> = ({ setObjField, defaultObject,value }) => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = FAQDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idfaq"
      label={LL("field_faq")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={FAQDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
    />
  );
};

export { AutocompleteFAQ };
