import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class OrderJournalRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("orderjurnal");
  }

}
