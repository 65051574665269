import { GeneralAutocomplete } from "components/elements/autocomplete/GeneralAutocomplete";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { CountryDto } from "dto/static/country.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CountryService } from "services/static/country.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: CountryDto | null;
  value?: string | number;
};

const service = new CountryService();
const AutocompleteCountry: React.FC<Props> = ({ setObjField, defaultObject,value }) => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = CountryDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idcountry"
      label={LL("field_country")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={CountryDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
    />
  );
};

export { AutocompleteCountry };
