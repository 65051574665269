import { GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnSendEmail } from "components/notification/sendtest/ColumnFromEmail";

export class ListColumns {
    static columnSendEmail = (LL: any) => {
      const obj: any = {
        renderCell: (params: GridRenderCellParams<any>) => {
          if (params.row !== undefined) {
            return <ColumnSendEmail object={params.row} />;
          } else {
            return "";
          }
        },
      };
  
      obj["field"] = "send_email";
      obj["headerName"] = LL("headerName_send_email");
      obj["hideable"] = true;
      obj["sortable"] = false;
  
      obj["flex"] = 1;
      obj["minWidth"] = 30;
  
      return obj;
    };
}  