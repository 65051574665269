import { Stack } from "@mui/material";
import React from "react";
import { useLabel } from "hooks/useLabel";
import { OrderDto } from "dto/sale/order.dto";
import { useOrderActions } from "hooks/useOrderActions";
import { CommonTools } from "tools/utils/common.tool";
import { MyButton } from "components/elements/button/MyButton";
type Props = {
  object: OrderDto;
  callbackFunction: () => void;
};

const DetailAction: React.FC<Props> = ({ object, callbackFunction }) => {
  const { LL } = useLabel();
  const {
    handleSetNewStatus,
    handleSetStatusVerified,
    handleSetStatusInProcess,
    handleSetStatusProcessed,
    handleSetStatusOnCourier,
    handleSetStatusDelivered,
    handleSetStatusArchived,
    handleSetStatusCancelled,
    handleSetStatusReturned,
    handleSetPayStatusPaid,
    handleSetPayStatusNeedReturn,
    handleSetPayStatusCanceled,
    handleSetPayStatusReturned,
  } = useOrderActions(object, callbackFunction);

  if (!object) return <></>;

  return (
    <Stack
      direction={"column"}
      alignContent={"center"}
      alignSelf={"center"}
      justifyContent={"center"}
      sx={{ height: "100%" }}
      spacing={1}
      
    >
      {CommonTools.processNumberToBoolean(object._cansetstatusnew) && (
        <MyButton onClick={handleSetNewStatus}>{LL("new_status")}</MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatusverified) && (
        <MyButton onClick={handleSetStatusVerified}>
          {LL("verified_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatusinprocess) && (
        <MyButton onClick={handleSetStatusInProcess}>
          {LL("in_process_status")}
        </MyButton>
      )}

      {CommonTools.processNumberToBoolean(object._cansetstatusprocessed) && (
        <MyButton onClick={handleSetStatusProcessed}>
          {LL("processed_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatusoncourier) && (
        <MyButton onClick={handleSetStatusOnCourier}>
          {LL("on_courier_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatusdelivered) && (
        <MyButton onClick={handleSetStatusDelivered}>
          {LL("delivered_status")}
        </MyButton>
      )}

      {CommonTools.processNumberToBoolean(object._cansetstatusarchive) && (
        <MyButton onClick={handleSetStatusArchived}>
          {LL("archived_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatuscanceled) && (
        <MyButton onClick={handleSetStatusCancelled}>
          {LL("cancelled_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetstatusreturned) && (
        <MyButton onClick={handleSetStatusReturned}>
          {LL("returned_status")}
        </MyButton>
      )}

      {CommonTools.processNumberToBoolean(object._cansetpaystatuspaid) && (
        <MyButton onClick={handleSetPayStatusPaid}>
          {LL("paid_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(
        object._cansetpaystatusneedreturn
      ) && (
        <MyButton onClick={handleSetPayStatusNeedReturn}>
          {LL("need_return_pay_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetpaystatuscanceled) && (
        <MyButton onClick={handleSetPayStatusCanceled}>
          {LL("canceled_pay_status")}
        </MyButton>
      )}
      {CommonTools.processNumberToBoolean(object._cansetpaystatusreturned) && (
        <MyButton onClick={handleSetPayStatusReturned}>
          {LL("returned_pay_status")}
        </MyButton>
      )}
    </Stack>
  );
};

export { DetailAction };
