import { ICurrentRoute } from "interfaces/currentroute.interface";
import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";

type Props = (
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  useDefaultApproach?: boolean
) => {
  mainUrl: string;
  listUrl: string;
  addUrl: string;
  setListUrl: (listUrl: string) => void;
};

const useGetUrlsList: Props = (
  mainObject,
  currentRoute,
  useDefaultApproach
) => {
  if (!useDefaultApproach) useDefaultApproach = true;
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  const { _setListUrl } = useResource();

  useEffect(() => {
    if (!mainObject || !currentRoute) return;
    // logger("currentRoute","DetailLanguage-----------");
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    // const lu = CommonTools.generateListUrl(mainObject, currentRoute);
    // setListUrl(lu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    // logger("currentRoute", lu);
  }, [mainObject, currentRoute]);

  useEffect(() => {
    if (useDefaultApproach) {
      _setListUrl(listUrl);
    }
  }, [listUrl]);

  return useMemo(
    () => ({
      mainUrl,
      listUrl,
      addUrl,
      setListUrl,
    }),
    [mainUrl, listUrl, addUrl]
  );
};

export { useGetUrlsList };
