import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useLabel } from "hooks/useLabel";
import { OrderDto } from "dto/sale/order.dto";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Archive,
  AssignmentReturn,
  AssignmentReturned,
  Cancel,
  DeliveryDining,
  DoNotDisturb,
  HourglassFull,
  HourglassTop,
  LocalShipping,
  Paid,
} from "@mui/icons-material";
import { useOrderActions } from "hooks/useOrderActions";
import { CommonTools } from "tools/utils/common.tool";
type Props = {
  object: OrderDto;
  callbackFunction: () => void;
};

const ColumnAction: React.FC<Props> = ({ object, callbackFunction }) => {
  const { LL } = useLabel();
  const {
    handleSetNewStatus,
    handleSetStatusVerified,
    handleSetStatusInProcess,
    handleSetStatusProcessed,
    handleSetStatusOnCourier,
    handleSetStatusDelivered,
    handleSetStatusArchived,
    handleSetStatusCancelled,
    handleSetStatusReturned,
    handleSetPayStatusPaid,
    handleSetPayStatusNeedReturn,
    handleSetPayStatusCanceled,
    handleSetPayStatusReturned,
  } = useOrderActions(object, callbackFunction);

  if (!object) return <></>;
  const firstGroup =
    CommonTools.processNumberToBoolean(object._cansetstatusnew) ||
    CommonTools.processNumberToBoolean(object._cansetstatusverified) ||
    CommonTools.processNumberToBoolean(object._cansetstatusinprocess);

  const secondGroup =
    CommonTools.processNumberToBoolean(object._cansetstatusprocessed) ||
    CommonTools.processNumberToBoolean(object._cansetstatusoncourier) ||
    CommonTools.processNumberToBoolean(object._cansetstatusdelivered);

  const thirdGroup =
    CommonTools.processNumberToBoolean(object._cansetstatusarchive) ||
    CommonTools.processNumberToBoolean(object._cansetstatuscanceled) ||
    CommonTools.processNumberToBoolean(object._cansetstatusreturned);

  const fourthGroup =
    CommonTools.processNumberToBoolean(object._cansetpaystatuspaid) ||
    CommonTools.processNumberToBoolean(object._cansetpaystatusneedreturn) ||
    CommonTools.processNumberToBoolean(object._cansetpaystatuscanceled) ||
    CommonTools.processNumberToBoolean(object._cansetpaystatusreturned);
  return (
    <Stack
      direction={"column"}
      alignContent={"center"}
      alignSelf={"center"}
      justifyContent={"center"}
      sx={{ height: "100%" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {firstGroup && (
        <ButtonGroup
          variant="text"
          size="small"
          aria-label="Basic button group"
        >
          {CommonTools.processNumberToBoolean(object._cansetstatusnew) && (
            <Tooltip title={LL("new_status")} placement="right">
              <IconButton onClick={handleSetNewStatus} sx={{ p: "2px" }}>
                <FiberNewIcon />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(object._cansetstatusverified) && (
            <Tooltip title={LL("verified_status")} placement="right">
              <IconButton onClick={handleSetStatusVerified} sx={{ p: "2px" }}>
                <DoneIcon />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetstatusinprocess
          ) && (
            <Tooltip title={LL("in_process_status")} placement="right">
              <IconButton onClick={handleSetStatusInProcess} sx={{ p: "2px" }}>
                <HourglassTop />
              </IconButton>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
      {secondGroup && (
        <ButtonGroup
          variant="text"
          size="small"
          aria-label="Basic button group"
        >
          {CommonTools.processNumberToBoolean(
            object._cansetstatusprocessed
          ) && (
            <Tooltip title={LL("processed_status")} placement="right">
              <IconButton onClick={handleSetStatusProcessed} sx={{ p: "2px" }}>
                <HourglassFull />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetstatusoncourier
          ) && (
            <Tooltip title={LL("on_courier_status")} placement="right">
              <IconButton onClick={handleSetStatusOnCourier} sx={{ p: "2px" }}>
                <DeliveryDining />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetstatusdelivered
          ) && (
            <Tooltip title={LL("delivered_status")} placement="right">
              <IconButton onClick={handleSetStatusDelivered} sx={{ p: "2px" }}>
                <LocalShipping />
              </IconButton>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
      {thirdGroup && (
        <ButtonGroup
          variant="text"
          size="small"
          aria-label="Basic button group"
        >
          {CommonTools.processNumberToBoolean(object._cansetstatusarchive) && (
            <Tooltip title={LL("archived_status")} placement="right">
              <IconButton onClick={handleSetStatusArchived} sx={{ p: "2px" }}>
                <Archive />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(object._cansetstatuscanceled) && (
            <Tooltip title={LL("cancelled_status")} placement="right">
              <IconButton onClick={handleSetStatusCancelled} sx={{ p: "2px" }}>
                <Cancel />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(object._cansetstatusreturned) && (
            <Tooltip title={LL("returned_status")} placement="right">
              <IconButton onClick={handleSetStatusReturned} sx={{ p: "2px" }}>
                <AssignmentReturned />
              </IconButton>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
      {fourthGroup && (
        <ButtonGroup
          variant="text"
          size="small"
          aria-label="Basic button group"
        >
          {CommonTools.processNumberToBoolean(object._cansetpaystatuspaid) && (
            <Tooltip title={LL("paid_status")} placement="right">
              <IconButton onClick={handleSetPayStatusPaid} sx={{ p: "2px" }}>
                <Paid />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetpaystatusneedreturn
          ) && (
            <Tooltip title={LL("need_return_pay_status")} placement="right">
              <IconButton
                onClick={handleSetPayStatusNeedReturn}
                sx={{ p: "2px" }}
              >
                <AssignmentReturn />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetpaystatuscanceled
          ) && (
            <Tooltip title={LL("canceled_pay_status")} placement="right">
              <IconButton
                onClick={handleSetPayStatusCanceled}
                sx={{ p: "2px" }}
              >
                <DoNotDisturb />
              </IconButton>
            </Tooltip>
          )}
          {CommonTools.processNumberToBoolean(
            object._cansetpaystatusreturned
          ) && (
            <Tooltip title={LL("returned_pay_status")} placement="right">
              <IconButton
                onClick={handleSetPayStatusReturned}
                sx={{ p: "2px" }}
              >
                <AssignmentReturned />
              </IconButton>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
    </Stack>
  );
};

export { ColumnAction };
