import RequestListDTO from "dto/app/requestlist.dto";
import GeneralAxiosRepository from "./generalaxios.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export default class GeneralRepository
  extends GeneralAxiosRepository
  implements IGeneralRepository
{
  protected mainUrl: string;

  constructor(mainUrl: string) {
    super();
    this.mainUrl = mainUrl;
  }

  get = async (id: string, cb?: any, cbParams?: any, idLanguage?: string) => {
    let url = `/${this.mainUrl}/show/${id}`;
    if (idLanguage) url = url.concat(`?_idlang=${idLanguage}`);

    return await this.getObject(url, cb, cbParams);
  };

  getList = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `/${this.mainUrl}`;
    return await this.getListObject(url, cb, cbParams, data);
  };

  add = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  update = async (id: string, cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/update/${id}`;
    return await this.putAxios(url, data, cb, cbParams);
  };

  delete = async (id: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/destroy/${id}`;
    return await this.deleteAxios(url, cb, cbParams);
  };

  addMultipart = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}`;
    return await this.postAxiosMultipart(url, data, cb, cbParams);
  };

  updateMultipart = async (id: string, cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/update/${id}`;
    return await this.postAxiosMultipart(url, data, cb, cbParams);
  };
}
