import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyDatePickerTimestamp } from "components/elements/datepicker/MyDatePickerTimestamp";
import { MyCKEditor } from "components/elements/form/MyCKEditor";
import { MyFileField } from "components/elements/form/MyFileField";
import { MyTextField } from "components/elements/form/MyTextField";
import { StaticSelect } from "components/elements/select/StaticSelect";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { AdvertisementDto } from "dto/static/advertisement.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { AdvertisementType } from "tools/types/advertisementtype";
import { TargetType } from "tools/types/targettype";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: AdvertisementDto;
  onSubmit: (data: AdvertisementDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormAdvertisement: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();
  const validators = forAdd
    ? RequiredValidator.getValidators(["file", "_name"])
    : RequiredValidator.getValidators(["_name"]);
  const [obj, disabled, setObjField, , setLanguage, setObject,vResults] =
    useForm<AdvertisementDto>(defaultObject, validators);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_btname"
            label={LL("field_btname")}
            setObjectField={setObjField}
            value={obj._btname}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <StaticSelect
            setObjField={setObjField}
            value={obj.type}
            service={AdvertisementType}
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            field="section"
            setObjField={setObjField}
            value={obj.section}
            service={AdvertisementSection}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="urltogo"
            label={LL("field_urltogo")}
            setObjectField={setObjField}
            value={obj.urltogo}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="imgwhidth"
            label={LL("field_imgwhidth")}
            setObjectField={setObjField}
            value={obj.imgwhidth}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="imgheight"
            label={LL("field_imgheight")}
            setObjectField={setObjField}
            value={obj.imgheight}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="imgmobwhidth"
            label={LL("field_imgmobwhidth")}
            setObjectField={setObjField}
            value={obj.imgmobwhidth}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="imgmobheight"
            label={LL("field_imgmobheight")}
            setObjectField={setObjField}
            value={obj.imgmobheight}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="advwhidth"
            label={LL("field_advwhidth")}
            setObjectField={setObjField}
            value={obj.advwhidth}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="advheight"
            label={LL("field_advheight")}
            setObjectField={setObjField}
            value={obj.advheight}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <StaticSelect
            field="target"
            setObjField={setObjField}
            value={obj.target}
            service={TargetType}
          />
        </Box>

        <Box mt={3}>
          <MyDatePickerTimestamp
            field="startdate"
            setObjField={setObjField}
            value={obj.startdate}
          />
        </Box>
        <Box mt={3}>
          <MyDatePickerTimestamp
            field="enddate"
            setObjField={setObjField}
            value={obj.enddate}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_shortdescription"
            label={LL("field_shortdescription")}
            setObjectField={setObjField}
            value={obj._shortdescription}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditor
            field="_description"
            setObjectField={setObjField}
            data={obj._description}
          />
        </Box>

        <Box mt={3}>
          <MyFileField
            field="file"
            label={LL("field_file")}
            setObj={setObject}
            obj={obj}
          />
        </Box>

        <Box mt={3}>
          <MyFileField
            field="filemob"
            label={LL("field_filemob")}
            setObj={setObject}
            obj={obj}
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormAdvertisement };
