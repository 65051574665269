import React from "react";

import { IPage } from "interfaces/page.interface";
import { PageService } from "services/static/page.service";
import { PageDto } from "dto/static/page.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormPage } from "components/static/page/FormPage";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { useResource } from "hooks/useResource";
const service = new PageService();
const EditPage: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    getByLanguage,
  } = useEditObject<PageDto>(mainObject, currentRoute, get, true);

  const onSubmit = (obj: PageDto) => {
    if (!obj) return;
    setLoading(true);
    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEdit;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
        };

    obj._idlang = parseInt(idLanguage.toString());
    service.update(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormPage
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditPage };
