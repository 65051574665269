import { Box } from "@mui/material";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { AlcGroup } from "dto/system/route.dto";

import { useLabel } from "hooks/useLabel";
import useObject from "hooks/useObject";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { AclRolesService } from "services/user/aclroles.service";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  groupName: string;
  idRole: string;
  trigger?: string;
};

const service = new AclRolesService();
const GroupAction: React.FC<Props> = ({ groupName, idRole, trigger = "" }) => {
  const { LL } = useLabel();
  const { handleTriggerAclRoles, handleTriggerAclRolesGlobal } = useResource();
  const get = (id: string, cb?: any, cbParams?: any) => {
    service.checkBulk(id, groupName, cb, cbParams);
  };
  const [, object, setObject] = useObject<AlcGroup>(
    get,
    idRole,
    [idRole, trigger],
    false
  );
  const [loadingAction, setLoadingAction] = useState(false);
  const handleChange = (result: ResultObjectDTO<AlcGroup>) => {
    if (!result) return;
    const object = result.obj ? result.obj : null;
    setObject(object);
    setLoadingAction(false);
    handleTriggerAclRoles();
    handleTriggerAclRolesGlobal();
  };
  const handleSubmit = (field: string, value: any) => {
    if (!object) return;
    object.value = value;
    setLoadingAction(true);
    service.changeBulk(
      handleChange,
      {},
      new AlcGroup(groupName, parseInt(idRole), value)
    );
  };

  if (!object) return null;
  //   if (loading) return null;

  const label = CommonTools.processNumberToBoolean(object.value)
    ? LL("unassign")
    : LL("assign");

  return (
    <Box>
      <MyCheckboxField
        field="value"
        checked={CommonTools.processNumberToBoolean(object.value)}
        isNumber
        label={label}
        setObjectField={handleSubmit}
      />
      <MyBackdrop loading={loadingAction} />
    </Box>
  );
};

export { GroupAction };
