import React from "react";
import { FAQRelationService } from "services/static/faqrelation.service";

import { IPage } from "interfaces/page.interface";

import { FAQRelationDto } from "dto/static/faqrelation.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormFAQRelation } from "components/special/faqrelation/FormFAQRelation";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new FAQRelationService();
const SpecialFAQRelationAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  
  const onSubmit = (obj: FAQRelationDto) => {
    if (!obj) return;
    obj.id_parent = parseInt(parentId.toString());
    obj.parent = parentType;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "faqrelation",
        cb: CommonTools.processLoadingCallback(setLoading),
        
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="faqrelation"
      />
      <DataDisplayBox>
        <FormFAQRelation
          defaultObject={
            new FAQRelationDto()
          }
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialFAQRelationAdd };
