import GeneralRepository from "repositories/general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class SMSTemplateRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("smstemplate");
  }
}
