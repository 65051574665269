import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import { useLabel } from "hooks/useLabel";

import { Send } from "@mui/icons-material";

import { DialogTestEmail } from "./DialogTestEmail";
import { MyButton } from "components/elements/button/MyButton";

const SendTestEmail: React.FC = () => {
  const { LL } = useLabel();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <MyButton onClick={handleOpen} endIcon={<Send />}>
        <Typography>{LL("Send_Email")}</Typography>
      </MyButton>
      <DialogTestEmail open={open} handleClose={handleClose} />
    </Box>
  );
};

export { SendTestEmail };
