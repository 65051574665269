import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { LabelTypes } from "tools/types/labeltypes";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class DisplayLabelDto implements Idto {
  id: number;
  identifier: string;
  en: string;
  ro: string;
  ru: string;
  constructor(
    id?: number,
    identifier?: string,
    en?: string,
    ro?: string,
    ru?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.en = en || "";
    this.ro = ro || "";
    this.ru = ru || "";
  }

  static getLabelLocal(identifier: string): DisplayLabelDto {
    const label = new DisplayLabelDto();
    label.identifier = CommonTools.getPrefixLabel(identifier);
    label.ro = identifier;
    label.ru = identifier;
    label.en = identifier;
    return label;
  }
}

export class LabelDto implements Idto {
  id?: number | string;
  _name?: string;
  identifier?: string;
  type?: number;
  status?: number;
  _idlang?: number;

  constructor(
    id?: number | string,
    name?: string,
    identifier?: string,
    type?: number,
    status?: number,
    idlang?: number
  ) {
    this.id = id || 0;
    this._name = name || "";
    this.identifier = identifier || "";
    this.type = type || LabelTypes.ADMIN;
    this.status = status || Status.ACTIVE;
    this._idlang = idlang || 0;
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("status", true));
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(LabelTypes.GA("general", true));
    obj.addFilter(t);
        
    return obj;
  }
}
