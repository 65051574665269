import { ServerSelect } from "components/elements/select/ServerSelect";
import RequestListDTO from "dto/app/requestlist.dto";
import { RoleDto } from "dto/user/role.dto";
import React from "react";
import {RoleService} from "services/user/role.service";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
};
const service = new RoleService();
const SelectRole: React.FC<Props> = ({
  field = "idrole",
  value,
  setObjectField,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={RoleDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
    />
  );
};

export { SelectRole };
