import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class CountryRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("country");
  }

  
  changeById = async (id: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/changecountry/${id}`;
    return await this.getObject(url, cb, cbParams);
  }

  changeByCode = async (code: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/changecountrycode/${code}`;
    return await this.getObject(url, cb, cbParams);
  }
}
