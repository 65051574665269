import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { ConfigTypes } from "tools/types/configtypes";
import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";

export class ConfigDto implements Idto {
  id?: number | string;
  identifier?: string;
  type?: number;
  value?: string;
  comment?: string;

  constructor(
    id?: number | string,
    identifier?: string,
    type?: number,
    value?: string,
    comment?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.type = type || 0;
    this.value = value || "";
    this.comment = comment || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Value",
        value: ["value"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Comment",
        value: ["comment"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "value"),
      ComponentCommonTools.columns_StandardColumn(LL, "comment"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(ConfigTypes.GA("general", true));
    obj.addFilter(t);

    return obj;
  }

  static getLocal(identifier: string, value?: string): ConfigDto {
    if (!value) value = "10";
    return new ConfigDto(
      0,
      CommonTools.getPrefixLabel(identifier),
      ConfigTypes.ADMIN,
      value,
      ""
    );
  }
}
