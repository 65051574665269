import { AppBar, Box, Stack } from "@mui/material";
import { NavButton } from "components/elements/button/NavButton";
import { useLabel } from "hooks/useLabel";
import React from "react";

const HeaderUserNoAuth: React.FC = () => {
  const { LL } = useLabel();
  return (
    <AppBar component="nav" sx={{ bgcolor: "white", px: 2, py: "15px" }}>
      <Stack
        direction={"row"}
        alignContent={"center"}
        justifyContent={"right"}
        spacing={2}>
        <Box
          sx={{
            px: 4,
            py: "6px",
            color: "primary.main",
            border: "1px solid",
            borderColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            "&:hover a": {
              color: "white",
              transition: "0.3s",
            },
            boxShadow: 1,
            transition: "0.3s",
            borderRadius: "4px",
          }}>
          <NavButton underline="none" href="login">
            {LL("header_link_login")}
          </NavButton>
        </Box>

        <Box
          sx={{
            px: 4,
            py: "6px",
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            boxShadow: 1,
            transition: "0.3s",
            borderRadius: "4px",
          }}>
          <NavButton underline="none" href="signup" sx={{ color: "white" }}>
            {LL("header_link_sign_up")}
          </NavButton>
        </Box>
      </Stack>
    </AppBar>
  );
};

export { HeaderUserNoAuth };
