import { Box } from "@mui/material";
import { GeneralFilterSelect } from "components/elements/select/GeneralFilterSelect";
import { LanguageDto } from "dto/static/language.dto";
import { useLanguage } from "providers/LanguageProvider";
import React from "react";

type Props = {
  value: string | number ;
  setValue: (value: string) => void;
};

const LanguageSelect: React.FC<Props> = ({ value, setValue }) => {
  const { languageSiteObjects } = useLanguage();

  if (!languageSiteObjects) return null;
  if (!languageSiteObjects.length) return null;
  return (
    <Box mb={3} sx={{ width: "100%", maxWidth: "400px" }}>
      <GeneralFilterSelect
        options={LanguageDto.parseToSelectOptions(languageSiteObjects)}
        setValue={setValue}
        value={value}
        _label="field_language"
      />
    </Box>
  );
};

export { LanguageSelect };
