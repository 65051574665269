import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DetailGridItem } from "./DetailGridItem";
import { VideoPlayer } from "../display/VideoPlayer";


const DetailGridVideo: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  let width: string | number = 640;
  let height: string | number = 360;
  let controls = false;
  const options = item.options ? item.options : null;

  if (options) {
    width = options.width ? options.width : 640;
    height = options.height ? options.height : 360;
    controls = options.controls ? options.controls : false;
  }else {
    return null;
  }
  const fields = options.fields ? options.fields : null;
  if(!fields) return null;

  
  return (
    <DetailGridItem item={item} index={index}>
      <VideoPlayer
        videoId={CommonTools.processObjectField(object, item.value)}
        width={width}
        height={height}
        controls={controls}
        type={parseInt(CommonTools.processObjectField(object, fields))}
      />
    </DetailGridItem>
  );
};

export { DetailGridVideo };
