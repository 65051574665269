import React from "react";
import { OfferService } from "services/product/offer.service";

import { IPage } from "interfaces/page.interface";

import { OfferDto } from "dto/product/offer.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormOffer } from "components/special/offer/FormOffer";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";

const service = new OfferService();
const SpecialOfferAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  
  const onSubmit = (obj: OfferDto) => {
    if (!obj) return;
    obj.idproduct = parseInt(parentId.toString());
    
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "offer",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="offer"
      />
      <DataDisplayBox>
        <FormOffer
          defaultObject={
            new OfferDto()
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialOfferAdd };
