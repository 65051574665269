import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyCKEditor } from "components/elements/form/MyCKEditor";
import { MyTextField } from "components/elements/form/MyTextField";
import { SendTestEmailDto } from "dto/notification/sendtestemail.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import RequiredValidator from "validators/required.validator";
import { SelectFromEmail } from "components/notification/fromemail/SelectFromEmail";

type Props = {
  defaultObject: SendTestEmailDto;
  onSubmit: (data: SendTestEmailDto) => void;
  loading: boolean;
};

const FormTestEmail: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,,vResults] = useForm<SendTestEmailDto>(
    defaultObject,
    RequiredValidator.getValidators(["toemail", "idfromemail"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectFromEmail
            setObjectField={setObjField}
            value={obj.idfromemail}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="toemail"
            label={LL("toemail")}
            setObjectField={setObjField}
            value={obj.toemail}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="cc"
            label={LL("cc")}
            setObjectField={setObjField}
            value={obj.cc}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="bcc"
            label={LL("bcc")}
            setObjectField={setObjField}
            value={obj.bcc}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_subject"
            label={LL("_subject")}
            setObjectField={setObjField}
            value={obj._subject}
            vResults={vResults}
          />
        </Box>
        <Box mt={3} maxWidth={"400px"}>
          <MyCKEditor
            field="_message"
            setObjectField={setObjField}
            data={obj._message}
            
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormTestEmail };
