import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

export class OfferDto implements Idto {
  id?: number | string;
  idproduct?: number;
  priority?: number;

  minq?: number;
  maxq?: number;

  price?: number | string;
  discount_percent?: number | string;
  discount_value?: number | string;
  real_price?: number | string;

  startdate?: number;
  enddate?: number;

  status?: number;

  static calculatePercentDiscount = (price: number, realPrice: number) => {
    if (typeof price === "string") price = parseFloat(price);
    if (typeof realPrice === "string") realPrice = parseFloat(realPrice);
    if (!price) return 0;
    if (!realPrice) return 0;
    const percent = price / realPrice;
    if (isNaN(percent)) return 0;
    if (percent === 1) return 0;
    return percent;
  };
  static calculateDiscountValue = (price: number, percent: number) => {
    if (typeof price === "string") price = parseFloat(price);
    if (typeof percent === "string") percent = parseFloat(percent);
    if (!price) return 0;
    if (!percent) return 0;
    return (price * percent) / 100;
  };
  static calculateRealPrice = (price: number, discountPercent: number) => {
    if (typeof price === "string") price = parseFloat(price);
    if (typeof discountPercent === "string")
      discountPercent = parseFloat(discountPercent);
    if (!price) return 0;
    if (!discountPercent) return price;
    return price - (price * discountPercent) / 100;
  };
  static calculatePrice = (realPrice: number, discountPercent: number) => {
    if (typeof realPrice === "string") realPrice = parseFloat(realPrice);
    if (typeof discountPercent === "string")
      discountPercent = parseFloat(discountPercent);
    if (!realPrice) return 0;
    if (!discountPercent) return realPrice;
    return realPrice + (realPrice * discountPercent) / 100;
  };
  static calculateDiscountPercent = (price: number, discountValue: number) => {
    if (typeof price === "string") price = parseFloat(price);
    if (typeof discountValue === "string")
      discountValue = parseFloat(discountValue);
    if (!price) return 0;
    return (discountValue * 100) / price;
  };

  constructor(
    id?: number | string,
    idproduct?: number,
    priority?: number,
    minq?: number,
    maxq?: number,
    price?: number,
    discount_percent?: number,
    discount_value?: number,
    real_price?: number,
    startdate?: number,
    enddate?: number,
    status?: number
  ) {
    this.id = id || 0;
    this.idproduct = idproduct || 0;
    this.priority = priority || 0;

    this.minq = minq || 1;
    this.maxq = maxq || 0;

    this.price = price || 0;
    this.discount_percent = discount_percent || 0;
    this.discount_value = discount_value || 0;
    this.real_price = real_price || 0;

    this.startdate = startdate || 0;
    this.enddate = enddate || 0;

    this.status = status || Status.ACTIVE;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "priority",
        value: ["priority"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "minq",
        value: ["minq"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "maxq",
        value: ["maxq"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "price",
        value: ["price"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "discount_percent",
        value: ["discount_percent"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "discount_value",
        value: ["discount_value"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "real_price",
        value: ["real_price"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "startdate",
        value: ["startdate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "enddate",
        value: ["enddate_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "priority"),
      ComponentCommonTools.columns_StandardColumn(LL, "minq"),
      ComponentCommonTools.columns_StandardColumn(LL, "maxq"),
      ComponentCommonTools.columns_StandardColumn(LL, "price"),
      ComponentCommonTools.columns_StandardColumn(LL, "discount_percent"),
      ComponentCommonTools.columns_StandardColumn(LL, "discount_value"),
      ComponentCommonTools.columns_StandardColumn(LL, "real_price"),
      ComponentCommonTools.columns_ConstantColumn(LL, "startdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "enddate"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete","offerfilter"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "priority"),
      ComponentCommonTools.columns_StandardColumn(LL, "minq"),
      ComponentCommonTools.columns_StandardColumn(LL, "maxq"),
      ComponentCommonTools.columns_StandardColumn(LL, "price"),
      ComponentCommonTools.columns_StandardColumn(LL, "discount_percent"),
      ComponentCommonTools.columns_StandardColumn(LL, "discount_value"),
      ComponentCommonTools.columns_StandardColumn(LL, "real_price"),
      ComponentCommonTools.columns_ConstantColumn(LL, "startdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "enddate"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete","offerfilter"],
        ["detailspecial"],
        undefined,
        "offer"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<OfferDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(OfferDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: OfferDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["real_price"])
    );
  };

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idproduct";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idproduct") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
