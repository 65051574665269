import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FAQAnswerType } from "tools/types/faqanswertype";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class FAQAnswerDto implements Idto {
  id?: number | string;
  idfaq?: string | number;
  order?: number;
  type?: number;
  fixed?: number;
  status?: number;
  _idlang?: number | string;
  _name?: string;
  _title?: string;
  _description?: string;
  _shortdescription?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    idfaq?: string,
    order?: number,
    type?: number,
    status?: number,
    _name?: string,
    _title?: string,
    _description?: string,
    _shortdescription?: string
  ) {
    this.id = id || 0;
    this.idfaq = idfaq || "";
    this.order = order || 0;
    this.type = type || FAQAnswerType.GENERAL;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this._title = _title || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["_title"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "description",
        value: ["_description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["_shortdescription"],
      },
    ]
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "video", "attachment"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "faqanswer"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(FAQAnswerType.GA("general", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<FAQAnswerDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["_name"])
        )
      );
    });
    return result;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idfaq";
    t.values = [parentId];
  
    req.filters = req.filters ?? [];
  
    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idfaq") continue;
  
      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
