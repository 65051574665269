import { Box, Skeleton } from "@mui/material";
import { AclRolesDto } from "dto/user/aclroles.dto";
import useObject from "hooks/useObject";
import React from "react";

import { CommonTools } from "tools/utils/common.tool";
import { ActionAclRole } from "./ActionAclRole";
import { useResource } from "hooks/useResource";
import { AclRolesService } from "services/user/aclroles.service";

type Props = {
  item: AclRolesDto;
  idRole: string;
  group: string;
};

const service = new AclRolesService();
const AclRoleItem: React.FC<Props> = ({ item, idRole, group }) => {
  const { triggerAclRoles } = useResource();
  const get = (routename: string, cb?: any, cbParams?: any) => {
    service.getByKeys(
      routename,
      CommonTools.processObjectField(item, ["routemethod"]),
      idRole,
      cb,
      cbParams
    );
  };

  const [loading, object, setObject] = useObject(
    get,
    CommonTools.processObjectField(item, ["routename"]),
    [triggerAclRoles],
    false,
    true,
    `${group}-${CommonTools.processObjectField(item, [
      "routename",
    ])}-${CommonTools.processObjectField(item, [
      "routemethod",
    ])}-${idRole}-${triggerAclRoles}`
  );

  if (!item) return null;
  if (loading)
    return (
      <Box sx={{ py: 1 }}>
        <Skeleton />
      </Box>
    );
  if (!object) return null;
  return <ActionAclRole object={object} setObject={setObject} />;
};

export { AclRoleItem };
