import React from "react";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { Box, Stack, Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { MyBreadcrumbs } from "../breadcrumb/MyBreadcrumbs";
import { CommonTools } from "tools/utils/common.tool";
import { MyButton } from "../button/MyButton";
import { NavButton } from "../button/NavButton";
import Idto from "interfaces/idto.interface";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  object: Idto | null;
  goUp: (e:any) => void;
  idParent: string;
  showAddButton?: boolean;
};
const HeaderListWithChildren: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  object,
  goUp,
  idParent,
  showAddButton = true,
}) => {
  const { LL } = useLabel();
  const access = RouteTools.checkRoutePermission(
    RouteTools.getPmHash_Add(mainObject)
  );
  showAddButton = showAddButton && access;
  return (
    <React.Fragment>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          my:3
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
          
        >
          <Typography variant="h4" >
            {LL(mainObject + "page")}{" "}
            {object && CommonTools.processObjectField(object, ["identifier"])}
          </Typography>
          {object && <MyButton cb={goUp}>{LL("Go up")}</MyButton>}
        </Stack>
        {showAddButton && (
          <NavButton
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={CommonTools.prepareObjectToAddChild(mainObject, idParent)}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderListWithChildren };
