import React from "react";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { Breadcrumbs } from "@mui/material";
import { BreadcrumbItem } from "./BreadcrumbItem";

type Props = {
  objects: BreadcrumbDto[];
  mainObject: string;
};

const MyBreadcrumbs: React.FC<Props> = ({ objects, mainObject }) => {
  return (
    <Breadcrumbs
      separator="›"
      className="breadcrumbs"
      aria-label="breadcrumb"
      color="text.disabled"
    >
      {objects.map((item, index) => (
        <BreadcrumbItem key={index} item={item} index={index} mainObject={mainObject} />
      ))}
    </Breadcrumbs>
  );
};

export { MyBreadcrumbs };