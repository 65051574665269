import React, { useState } from "react";
import {DeliveryMethodLocationService} from "services/delivery/deliverymethodlocation.service";

import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { DeliveryMethodLocationDto } from "dto/delivery/deliverymethodlocation.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormDeliveryMethodLocation } from "components/delivery/deliverymethodlocation/FormDeliveryMethodLocation";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

const service = new DeliveryMethodLocationService();
const AddDeliveryMethodLocation: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (obj: DeliveryMethodLocationDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
      <FormDeliveryMethodLocation defaultObject={new DeliveryMethodLocationDto()} onSubmit={onSubmit} loading={loading} />
      </DataDisplayBox> 
    </React.Fragment>
  );
};

export { AddDeliveryMethodLocation };
