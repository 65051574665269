import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Container } from "@mui/material";
import { HeaderUserNoAuth } from "components/general/HeaderUserNoAuth";
import { useUser } from "hooks/useUser";
import { LoginForm } from "components/authentication/LoginForm";

const LoginPage: React.FC<IPage> = () => {
  const { login } = useUser();
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}>
      <HeaderUserNoAuth />
      <Box
        className="shadowBox"
        sx={{
          mt: "64px",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          px: { xs: 4, sm: 10 },
          pb: 6,
          pt: 3,
          borderRadius: 4,
        }}>
        <LoginForm onSubmit={login} />
      </Box>
    </Container>
  );
};

export { LoginPage };
