import React from "react";
import { OfferFilterService } from "services/product/offerfilter.service";

import { IPage } from "interfaces/page.interface";

import { OfferFilterDto } from "dto/product/offerfilter.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";

import { FormOfferFilter } from "components/special/offerfilter/FormOfferFilter";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { useAddSpecialThirdLevel } from "hooks/useAddSpecialThirdLevel";
import { HeaderAddSpecialThirdLevel } from "components/elements/add/HeaderAddSpecialThirdLevel";

const service = new OfferFilterService();
const SpecialOfferFilterAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const {
    mainUrl,
    loading,
    setLoading,
    parentId,
    parentType,
    firstLevelParentId,
    firstLevelParentType,
  } = useAddSpecialThirdLevel(currentRoute);

  const onSubmit = (obj: OfferFilterDto) => {
    if (!obj) return;
    obj.idoffer = parseInt(parentId.toString());

    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "offerfilter",
        firstLevelParentId,
        firstLevelParentType,
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="offerfilter"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <DataDisplayBox>
        <FormOfferFilter
          defaultObject={new OfferFilterDto()}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialOfferFilterAdd };
