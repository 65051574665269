import React from "react";
import { IPage } from "interfaces/page.interface";

import { StaticHTTPCodePage } from "components/general/StaticHTTPCodePage";

const ForbiddenPage: React.FC<IPage> = () => {
  return <StaticHTTPCodePage code={"403"} label={"403_forbidden"} />;
};

export { ForbiddenPage };
