import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import PaymentsIcon from "@mui/icons-material/Payments";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useLabel } from "hooks/useLabel";

import { OrderDto } from "dto/sale/order.dto";

type Props = {
    object: OrderDto;
  };

const ColumnStatus: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("Status")} placement="right">
          <LocalMallIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "status_name"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("paystatus")} placement="right">
          <PaymentsIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "paystatus_name"])}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { ColumnStatus };
