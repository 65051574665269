import Idto from "../../interfaces/idto.interface";

export default class MessageDto implements Idto {
  id: string;
  code?: string;
  message?: string;
  type?: number;
  timeout?: number;
  uuid?: string;
  constructor() {
    this.id = "";
    this.code = "";
    this.message = "";
    this.type = 0;
    this.timeout = 10000;
    this.uuid = "";
  }

  static prepareMessage(
    code: string,
    message: string,
    type: number,
  ) {
    const messageDto = new MessageDto();
    messageDto.code = code;
    messageDto.message = message;
    messageDto.type = type;
    return messageDto;
  }
}
