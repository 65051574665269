import React, { useContext, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  SourceEditing,
  FontSize,
  Alignment,
  Link,
  Heading,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  List,
  Indent,
  IndentBlock,
  MediaEmbed,
  SimpleUploadAdapter,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  LinkImage,
  ImageUpload,
  ImageInsert,
  ImageInsertViaUrl,
} from "ckeditor5";

import { JWTContext } from "providers/JWTProvider";
import { FileService } from "services/system/file.service";

type Props = {
  field: string;
  data?: string;
  setObjectField: (field: string, value: any) => void;
  onEditorReady?: (editor: any) => void;
};
const service = new FileService();
const MyCKEditor: React.FC<Props> = ({
  data,
  field,
  setObjectField,
  onEditorReady,
}) => {
  const editorRef = useRef<any>(null);
  const handleChange = (event: any, editor: any) => {
    if (!setObjectField) return;
    setObjectField(field, editor.getData());
  };
  const { token } = useContext(JWTContext);

  useEffect(() => {
    if (editorRef.current && onEditorReady) {
      onEditorReady(editorRef.current);
    }
  }, [editorRef.current, onEditorReady]);

  return (
    <CKEditor
      data={data ?? ""}
      editor={ClassicEditor}
      onChange={handleChange}
      onReady={(editor) => {
        editorRef.current = editor;
        if (onEditorReady) {
          onEditorReady(editor);
        }
      }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "sourceEditing",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "alignment",
            "|",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "insertImage",
            "mediaEmbed",
          ],

          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          SourceEditing,
          FontSize,
          Alignment,
          Link,
          Heading,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          List,
          Indent,
          IndentBlock,
          MediaEmbed,
          SimpleUploadAdapter,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          LinkImage,
          ImageUpload,
          ImageInsert,
          ImageInsertViaUrl,
        ],
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
          ],
          insert: {
            type: "auto",
            integrations: ["upload", "url"],
          },
        },
        simpleUpload: {
          uploadUrl: service.uploadImageCkEditor(),
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        mediaEmbed: {
          previewsInData: true,
        },
      }}
    />
  );
};

export { MyCKEditor };
