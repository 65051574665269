import { Skeleton } from "@mui/material";
import { MySelect } from "components/elements/select/MySelect";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { FilterDictionarDto } from "dto/product/filterdictionar.dto";
import { useList } from "hooks/useList";
import React from "react";
import { FilterDictionarService } from "services/product/filterdictionar.service";

import { Status } from "tools/types/status";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  idFilter: number;
  all?: boolean;
};
const service = new FilterDictionarService();
const SelectFilterDictionar: React.FC<Props> = ({
  field = "idfilterdictionar",
  value,
  setObjectField,
  idFilter,
  all = false,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };

  const [loading, objects] = useList<FilterDictionarDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idfilter", [idFilter.toString()]),
      ],
      1,
      -1
    ),
    [idFilter],
    true
  );

  if (loading) return <Skeleton variant="rectangular" height={50} />;
  if (!objects) return null;
  return (
    <MySelect
      options={FilterDictionarDto.parseToSelectOptions(objects, all)}
      _label={"field_filterdictionar"}
      setObjectField={setObjectField}
      value={value}
      field={field}
    />
  );
};

export { SelectFilterDictionar };
