import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import {LanguageService} from "services/static/language.service";
import { LanguageDto } from "dto/static/language.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { CommonTools } from "tools/utils/common.tool";
import { LocalStorageTools } from "api/localstorage.api";
import { Config } from "tools/utils/config";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { JWTDto } from "dto/app/jwt.dto";
import { JWTContext } from "./JWTProvider";

type Props = {
  languageSiteObjects: Array<LanguageDto>;
  selectedLanguageCode: string;
  changeLanguage: (code: string) => void;
  idSelectedLanguage: string;
};

export const LanguageContext = createContext<Props>({
  languageSiteObjects: [],
  selectedLanguageCode: Config.DEFAULT_LANGUAGE,
  changeLanguage: (code: string) => {},
  idSelectedLanguage: "",
});

const service = new LanguageService();

type StateResource = {
  loading: boolean;
  languageSiteObjects: Array<LanguageDto>;
  selectedLanguageCode: string;
  idSelectedLanguage: string;
};

const reducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case "set_loading": {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }
    case "set_language_site_objects": {
      return {
        ...state,
        languageSiteObjects: action.payload.languageSiteObjects,
        loading: false,
      };
    }
    case "select_language": {
      LocalStorageTools.saveValue(
        "selected_language_code",
        action.payload.selectedLanguageCode
      );

      return {
        ...state,
        selectedLanguageCode: action.payload.selectedLanguageCode,
        idSelectedLanguage: getLanguageIdByCode(
          action.payload.selectedLanguageCode,
          state.languageSiteObjects
        ),
      };
    }
    case "set_id_selected_language": {
      return {
        ...state,
        idSelectedLanguage: action.payload.idSelectedLanguage,
      };
    }

    default:
      return state;
  }
};
var isRequesting = false;
export const LanguageProvider: React.FC<IProvider> = ({ children }) => {
  const { processToken } = useContext(JWTContext);
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    languageSiteObjects: [],
    selectedLanguageCode: getDefaultLanguage(),
    idSelectedLanguage: "",
  });
  const {
    loading,
    languageSiteObjects,
    selectedLanguageCode,
    idSelectedLanguage,
  }: StateResource = state;

  const getList = useCallback(() => {
    if (isRequesting) return;
    isRequesting = true;
    service.getListAdmin(handleGetList, {});
  }, []);

  const handleGetList = (result: ResultListDTO) => {
    if (!result) return;
    if (CommonTools.processNumberToBoolean(result.error)) return;
    const objects = result.objects ? result.objects : [];
    dispatch({
      type: "set_language_site_objects",
      payload: { languageSiteObjects: objects },
    });
    isRequesting = false;
  };

  useEffect(() => {
    getList();
  }, [getList]);

  const changeLanguage = (code: string) => {
    dispatch({
      type: "select_language",
      payload: { selectedLanguageCode: code },
    });
    service.changeLanguageByCode(code, handleChange, {});
  };
  const handleChange = (result: ResultObjectDTO<JWTDto>) => {
    if (!result) return;
    if (CommonTools.processNumberToBoolean(result.error)) return;
    const obj = result.obj ? result.obj : null;
    if (!obj) return;
    processToken(obj.token);
    window.location.reload();
  };

  const setLanguageId = useCallback(() => {
    if (!selectedLanguageCode) return;
    if (!languageSiteObjects) return;
    if (!Array.isArray(languageSiteObjects)) return;
    if (!languageSiteObjects.length) return;
    dispatch({
      type: "set_id_selected_language",
      payload: {
        idSelectedLanguage: getLanguageIdByCode(
          selectedLanguageCode,
          languageSiteObjects
        ),
      },
    });
  }, [languageSiteObjects, selectedLanguageCode]);

  useEffect(() => {
    setLanguageId();
  }, [setLanguageId]);

  const value = {
    languageSiteObjects,
    selectedLanguageCode,
    changeLanguage,
    idSelectedLanguage,
  };

  return loading ? (
    <Loading />
  ) : (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

type HookResult = Props & {
  getCurrentLanguage: () => LanguageDto | null;
};
export const useLanguage = (): HookResult => {
  const {
    languageSiteObjects,
    selectedLanguageCode,
    changeLanguage,
    idSelectedLanguage,
  } = useContext(LanguageContext);

  const getCurrentLanguage = () => {
    if (!selectedLanguageCode) return null;
    if (!languageSiteObjects || !languageSiteObjects.length) return null;
    const obj = languageSiteObjects.find((item) => {
      return (
        CommonTools.processObjectField(item, ["code"]).toLowerCase() ===
        selectedLanguageCode.toLowerCase()
      );
    });
    return obj ?? null;
  };
  return {
    languageSiteObjects,
    selectedLanguageCode,
    getCurrentLanguage,
    changeLanguage,
    idSelectedLanguage,
  };
};
const getDefaultLanguage = (): string => {
  const languageCode = LocalStorageTools.getValue("selected_language_code");

  if (!languageCode) return Config.DEFAULT_LANGUAGE;
  else return languageCode;
};

const getLanguageIdByCode = (
  code: string,
  languageSiteObjects: Array<LanguageDto>
): string => {
  if (!languageSiteObjects) return "";
  if (!Array.isArray(languageSiteObjects)) return "";
  if (!languageSiteObjects.length) return "";
  const obj = languageSiteObjects.find((item) => {
    return (
      CommonTools.processObjectField(item, ["code"]).toLowerCase() ===
      code.toLowerCase()
    );
  });
  return obj ? (obj.id ? obj.id.toString() : "") : "";
};
