import {  Box, Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { LabelDto } from "dto/static/label.dto";
import { useDetailObject } from "hooks/useDetailObject";
import { IPage } from "interfaces/page.interface";
import React from "react";
import {LabelService} from "services/static/label.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { LanguageSelect } from "components/static/language/LanguageSelect";

const service = new LabelService();
const DetailLabel: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any,idLanguage?:string) => {
    service.get(id, cb, cbParams,idLanguage);
  };

  const { mainUrl, editUrl, listUrl, object, loading ,idLanguage,setIdLanguage} =
    useDetailObject<LabelDto>(mainObject, currentRoute, get,true);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        idLanguage={idLanguage}
      />
      <DataDisplayBox>
        <Box>
            <LanguageSelect value={idLanguage} setValue={setIdLanguage}/>
        </Box>
        <Grid>
          <DetailGrid object={object} fields={LabelDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailLabel };
