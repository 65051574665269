import { Box, Stack, IconButton } from "@mui/material";
import Idto from "interfaces/idto.interface";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "../button/NavButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { MyBreadcrumbs } from "../breadcrumb/MyBreadcrumbs";
import { SpecialTopInfo } from "components/special/SpecialTopInfo";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  object: Idto;
  mainObject: string;
  mainUrl: string;
  editUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  firstLevelParentId: string;
  firstLevelParentType: string;
  idLanguage?: string;
  anchorTab?: string;
  showEditBtn?: boolean;
};
const HeaderDetailSpecialThirdLevel: React.FC<Props> = ({
  object,
  mainObject,
  mainUrl,
  parentType,
  parentId,
  specialType,
  editUrl,
  idLanguage,
  anchorTab = "",
  firstLevelParentId,
  firstLevelParentType,
  showEditBtn = true,
}) => {
  const access = RouteTools.checkRoutePermission(
    RouteTools.getPmHash_Update(specialType)
  );
  showEditBtn = showEditBtn && access;
  return (
    <React.Fragment>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbDetailThirdLevel(
          firstLevelParentType,
          firstLevelParentId,
          parentType,
          parentId,
          specialType
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: showEditBtn ? "space-between" : "flex-start",

          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <NavButton
              href={mainUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </NavButton>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"detail"}
          />
        </Box>
        {showEditBtn && (
          <Box>
            <NavButton
              href={editUrl}
              sx={{ ml: "auto" }}
              _hstate={CommonTools.prepareObjectForUpdate(
                object,
                mainObject,
                anchorTab,
                idLanguage
              )}
              _mainurl={mainUrl}
              component={IconButton}
              className="editBtn"
            >
              <EditIcon />
            </NavButton>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderDetailSpecialThirdLevel };
