import { List } from "@mui/material";
import { GroupedAclDto } from "dto/system/route.dto";
import React from "react";
import { MenuAclItem } from "./MenuAclItem";

type Props = {
  objects: GroupedAclDto[];
};

const MenuAcl: React.FC<Props> = ({ objects }) => {
  return (
    <List
      sx={{
        
        height: 600,
        overflowY: "auto",
      }}
    >
      {objects.map((object, index) => {
        return <MenuAclItem key={index} object={object} />;
      })}
    </List>
  );
};

export { MenuAcl };
