import { FileRepository } from "repositories/system/file.repository";
import { GeneralService } from "services/general.service";

export class FileService extends GeneralService<FileRepository> {
  constructor() {
    super(new FileRepository());
  }

  uploadImageCkEditor = () => {
    return this.repository.uploadImageCkEditor();
  };

  uploadFileCkEditor = () => {
    return this.repository.uploadFileCkEditor();
  };
}
