import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DetailGridItem } from "./DetailGridItem";
import { Image } from "components/elements/display/Image";

const DetailGridImage: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
    
  return (
    <DetailGridItem item={item} index={index}>
      <Image
        url={CommonTools.processObjectField(object, item.value)}
        typeMeasure="pixel"
        sizeInPx={{
          width: item.options?.width
            ? typeof item.options.width === "number"
              ? item.options.width
              : parseInt(item.options.width)
            : 100,
          height: item.options?.height
            ? typeof item.options.height === "number"
              ? item.options.height
              : parseInt(item.options.height)
            : 100,
        }}
        withLinK={item.options?.withLink || true}
      />
    </DetailGridItem>
  );
};

export { DetailGridImage };
