import { Box, Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import React, { useCallback, useEffect, useState } from "react";
import { Status } from "tools/types/status";
import { HasId, KeyObject } from "./ServerCategoryBlockSelect";
import SelectOptions from "dto/app/selectoptions.dto";
import { ServerCategoryItemSelect } from "./ServerCategoryItemSelect";

type Props = {
  setObjectField: (field: string, value: any) => void;  
  getList: (cb?: any, cbParams?: any, req?: RequestListDTO) => void;
  idParent: string;
  level: number;
  processOptions: (objects: HasId[]) => Array<SelectOptions>;
  field: string;
  list: KeyObject;
  addToList: (key: number, value: string | number) => void;
  removeFromList: (key: number) => void;
  label:string
};

const ServerCategoryLevelSelect: React.FC<Props> = ({
  setObjectField,
  getList,
  idParent,
  level,
  list,
  processOptions,
  field,
  addToList,
  removeFromList,
  label
}) => {
  const [objects, setObjects] = useState<HasId[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getListObjects = useCallback(() => {
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = -1;
    req.filters = [
      RequestFilterDTO.prepareFilter("idparent", [idParent]),
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    setLoading(true);
    getList(handleGetList, {}, req);
  }, [idParent]);

  const handleGetList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : [];
    setObjects(objects);
  };

  useEffect(() => {
    getListObjects();
  }, [getListObjects]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!objects) loading = true;
    setLoading(loading);
  }, [objects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  return loading ? (
    <Skeleton />
  ) : objects && objects.length > 0 ? (
    <Box>
      <ServerCategoryItemSelect
        setObjectField={setObjectField}
        objects={processOptions(objects)}
        level={level}
        field={field}
        getList={getList}
        processOptions={processOptions}
        keyList={list}
        removeFromList={removeFromList}
        addToList={addToList}
        label={label}
      />
    </Box>
  ) : null;
};

export { ServerCategoryLevelSelect };
