import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import { FilterTools } from "tools/utils/filter.tool";

import { Status } from "tools/types/status";
import { Params } from "dto/system/params.dto";

export class SMSTemplateDto implements Idto {
  id?: number | string;
  identifier?: string;
  status?: number;
  fromname?: string;
  tonumber?: string;

  _idlang?: number | string;

  _sms?: string;

  parameters: Array<Params>;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    identifier?: string,
    status?: number,
    fromname?: string,
    tonumber?: string,
    _sms?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.status = status || Status.ACTIVE;
    this.fromname = fromname || "";
    this.tonumber = tonumber || "";
    this._idlang = _idlang || 0;
    this._sms = _sms || "";

    this.parameters = [];
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "fromname",
        value: ["fromname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "tonumber",
        value: ["tonumber"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "sms",
        value: ["_sms"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_StandardColumn(LL, "fromname"),
      ComponentCommonTools.columns_StandardColumn(LL, "tonumber"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();
    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);
    return obj;
  }

  static parseToSelectOptions(
    data: Array<SMSTemplateDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(SMSTemplateDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: SMSTemplateDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["identifier"])
    );
  };
}


