import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";
import PersonIcon from "@mui/icons-material/Person";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { OrderDto } from "dto/sale/order.dto";

type Props = {
  object: OrderDto;
};

const ColumnClient: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
      direction={"column"}
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("client_name")} placement="right">
          <PersonIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "client_name"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("client_mobil")} placement="right">
          <ContactPhoneIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "client_mobil"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("client_email")} placement="right">
          <ContactMailIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [ "client_email"])}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Tooltip title={LL("client_othercontacts")} placement="right">
          <ContactEmergencyIcon />
        </Tooltip>
        <Typography>
          {CommonTools.processObjectField(object, [
            
            "client_othercontacts",
          ])}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { ColumnClient };
