import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { DeliveryMethodDto } from "./deliverymethod.dto";
import { CountryDto } from "dto/static/country.dto";
import { LocationDto } from "dto/static/location.dto";

export class DeliveryMethodLocationDto implements Idto {
  id?: number | string;
  iddeliverymethod?: number | string;
  idcountry?: number | string;
  idlocation?: number | string;

    deliveryMethodObj?: DeliveryMethodDto;
    countryObj?: CountryDto;
    locationObj?: LocationDto;
  constructor(
    id?: number | string,
    iddeliverymethod?: number | string,
    idcountry?: number | string,
    idlocation?: number | string
  ) {
    this.id = id || 0;
    this.iddeliverymethod = iddeliverymethod || 0;
    this.idcountry = idcountry || 0;
    this.idlocation = idlocation || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DeliveryMethod",
        value: ["deliveryMethodObj", "_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Country",
        value: ["countryObj", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Location",
        value: ["locationObj", "_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "deliveryMethodObj",
        "_name"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "countryObj",
        "name"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "locationObj",
        "_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<DeliveryMethodLocationDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(DeliveryMethodLocationDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: DeliveryMethodLocationDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };
}
