import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class OrderRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("order");
  }

  setStatusNew = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusnew`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  setStatusVerified = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusverified`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  setStatusInProcess = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusinprocess`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  setStatusProcessed = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusprocessed`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  setStatusOnCourier = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusoncourier`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  setStatusDelivered = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusdelivered`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setStatusArchived = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusarchive`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setStatusCancelled = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatuscanceled`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setStatusReturn = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setstatusreturned`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setPayStatusPaid = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setpaystatuspaid`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setPayStatusNeedReturn = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setpaystatusneedreturn`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setPayStatusCanceled = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setpaystatuscanceled`;
    return await this.postAxios(url, data, cb, cbParams);
  }

  setPayStatusReturned = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/setpaystatusreturned`;
    return await this.postAxios(url, data, cb, cbParams);
  }
}
