import { ServerSelect } from "components/elements/select/ServerSelect";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { DeliveryMethodDto } from "dto/delivery/deliverymethod.dto";
import React from "react";
import { DeliveryMethodService } from "services/delivery/deliverymethod.service";
import { Status } from "tools/types/status";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
};
const service = new DeliveryMethodService();
const SelectDeliveryMethod: React.FC<Props> = ({
  field = "iddeliverymethod",
  value,
  setObjectField,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!data) data = new RequestListDTO();
    data.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];

    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={DeliveryMethodDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label="DeliveryMethod"
    />
  );
};

export { SelectDeliveryMethod };
