import React from "react";
import { Box, Stack } from "@mui/material";
import { CurrentUserInfo } from "components/authentication/CurrentUserInfo";
import { LanguageSiteOptions } from "components/static/language/LanguageSiteOptions";

const TopBar: React.FC = () => {
  return (
    <Box
      sx={{ 
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Box>
          <LanguageSiteOptions />
        </Box>
        <Box>
          <CurrentUserInfo />
        </Box>
      </Stack>
    </Box>
  );
};

export { TopBar };
