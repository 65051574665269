import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class ProductDto implements Idto {
  id?: number | string;
  idcategory?: number;
  categorycode?: string;

  order?: number;
  status?: number;

  refcode?: string;

  minq?: number;
  defaultq?: number;
  maxq?: number;

  avorder?: number;
  recomendat?: number;
  showonhome?: number;

  _idlang?: number | string;

  _name?: string;
  _title?: string;
  _slug?: string;

  _description?: string;
  _shortdescription?: string;
  _metakey?: string;
  _metadescription?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    order?: number,
    status?: number,
    refcode?: string,
    minq?: number,
    defaultq?: number,
    maxq?: number,
    avorder?: number,
    recomendat?: number,
    showonhome?: number,
    _name?: string,
    _title?: string,
    _slug?: string,
    _description?: string,
    _shortdescription?: string,
    _metakey?: string,
    _metadescription?: string
  ) {
    this.id = id || 0;

    this.order = order || 0;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this.refcode = refcode || "";
    this.minq = minq || 1;
    this.defaultq = defaultq || 1;
    this.maxq = maxq || 0;
    this.avorder = avorder || 0;
    this.recomendat = recomendat || 0;
    this.showonhome = showonhome || 0;
    this._title = _title || "";
    this._slug = _slug || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
    this._metakey = _metakey || "";
    this._metadescription = _metadescription || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "category_name",
        value: ["categoryProductObj", "full_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "refcode",
        value: ["refcode"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "slug",
        value: ["_slug"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "minq",
        value: ["minq"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "defaultq",
        value: ["defaultq"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "maxq",
        value: ["maxq"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "avorder",
        value: ["avorder_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "recomendat",
        value: ["recomendat_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "showonhome",
        value: ["showonhome_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["_title"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "description",
        value: ["_description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["_shortdescription"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "metakey",
        value: ["_metakey"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "metadescription",
        value: ["_metadescription"],
      },
    ].concat(CommonTools.displayMedia());
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_StandardColumn(LL, "refcode"),
      ComponentCommonTools.columns_ConstantColumn(LL, "recomendat"),
      ComponentCommonTools.columns_ConstantColumn(LL, "showonhome"),
      ComponentCommonTools.columns_StandardColumn(LL, "minq"),
      ComponentCommonTools.columns_StandardColumn(LL, "maxq"),
      ComponentCommonTools.columns_StandardColumn(LL, "_offerinfo"),
      ComponentCommonTools.columns_StandardColumn(LL, "_filterinfo"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "defaultOfferObj",
        "real_price"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "edit",
          "detail",
          "delete",
          "gallery",
          "attachment",
          "video",
          "offer",
          "productfilter",
        ],
        ["detail", "attachment", "video"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<ProductDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(ProductDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: ProductDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };
}
