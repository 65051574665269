import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextFieldConfig } from "components/elements/form/MyTextFieldConfig";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { UserDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { UserConfigType } from "tools/types/userconfigtype";
import RequiredValidator from "validators/required.validator";
import { FormUserAdd } from "./FormUserAdd";
import IValidator from "interfaces/validator.interface";

type Props = {
  defaultObject: UserDto;
  onSubmit: (data: UserDto) => void;
  loading: boolean;
  forAdd?: boolean;
  forProfile?: boolean;
};
const FormUser: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
  forProfile = false,
}) => {
  const { LL } = useLabel();
  const validators: IValidator[] = forAdd
    ? RequiredValidator.getValidators(["email", "password"])
    : [];
  const [obj, disabled, setObjField, setObjConfig] = useForm<UserDto>(
    defaultObject,
    validators
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {forAdd && <FormUserAdd obj={obj} setObjField={setObjField} />}
        {!forProfile && (
          <Box mt={3}>
            <StatusSelect
              setObjField={setObjField}
              value={obj.status}
              typeStatus="user"
            />
          </Box>
        )}
        <Box mt={3}>
          <MyTextFieldConfig
            field="configs"
            label={LL("field_first_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.FIRST_NAME}
            arrConfig={obj.configs}
          />
        </Box>
        <Box mt={3}>
          <MyTextFieldConfig
            field="configs"
            label={LL("field_second_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.SECOND_NAME}
            arrConfig={obj.configs}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormUser };
