import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DetailGridItem } from "./DetailGridItem";

const DetailGridText: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  return (
    <DetailGridItem item={item} index={index}>
      {CommonTools.processObjectField(object, item.value)}
    </DetailGridItem>
  );
};

export { DetailGridText };
