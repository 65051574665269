import React from "react";
import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";

const ServerError: React.FC = () => {
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ fontSize: "150px", fontWeight: "500" }}>500</Box>
      <Box sx={{ fontSize: "30px" }}>Server Error</Box>
      <Box mt={3}>
        <MyButton cb={handleReload}>Reload</MyButton>
      </Box>
    </Box>
  );
};

export { ServerError };
