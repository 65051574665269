import React from "react";
import { Checkbox, FormControlLabel, CheckboxProps } from "@mui/material";


type Props = CheckboxProps & {
  label: string | React.ReactNode;
  field: string;
  setObjectField: (field: string, value: any) => void;
  isNumber?: boolean;
};

const MyCheckboxField: React.FC<Props> = ({
  label,
  field,
  setObjectField,
  isNumber = false,
  ...props
}) => {
  const handleChange = (event: any) => {
    if (isNumber) {
      setObjectField(
        field,
        event.target.checked ? 1 : 0
      );
    } else {
      setObjectField(field, event.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          {...props}
          
        />
      }
      label={label}
    />
  );
};

export { MyCheckboxField };
