import { Box } from "@mui/material";
import { GroupedAclDto } from "dto/system/route.dto";
import React from "react";
import { GroupAclItem } from "./GroupAclItem";

type Props = {
  objects: GroupedAclDto[];
  idRole: string;
};

const GroupAclList: React.FC<Props> = ({ objects, idRole }) => {
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "md",
        py: 3,
      }}>
      {objects.map((group, index) => {
        return (
          <GroupAclItem
            key={index}
            group={group}
            openDefault={false}
            idRole={idRole}
          />
        );
      })}
    </Box>
  );
};

export { GroupAclList };
