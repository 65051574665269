import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class LanguageDto implements Idto {
  id?: number | string;
  name?: string;
  code?: string;
  html_code?: string;
  nativename?: string;
  order?: number;
  status?: number;
  statusadmin?: number;
  urlprefix?: string;
  constructor(
    id?: number | string,
    name?: string,
    code?: string,
    html_code?: string,
    nativename?: string,
    order?: number,
    status?: number,
    statusadmin?: number,
    urlprefix?: string
  ) {
    this.id = id || 0;
    this.name = name || "";
    this.code = code || "";
    this.html_code = html_code || "";
    this.nativename = nativename || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this.statusadmin = statusadmin || Status.ACTIVE;
    this.urlprefix = urlprefix || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Code",
        value: ["code"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Native_name",
        value: ["nativename"],
      },
      {
        typeField: Types.FIELD_TYPE_NUMBER,
        label: "Order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Statusadmin",
        value: ["statusadmin_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Html_code",
        value: ["html_code"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "urlprefix",
        value: ["urlprefix"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "code"),
      ComponentCommonTools.columns_StandardColumn(LL, "nativename"),
      ComponentCommonTools.columns_StandardColumn(LL, "urlprefix"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<LanguageDto>): Array<SelectOptions> {
    if(!data) return [];
    if(!Array.isArray(data)) return [];
    if(!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["nativename"])
        )
      );
    });
    return result;
  }
}
