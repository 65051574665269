import { useEffect, useState, useCallback, useMemo } from "react";
import RequestListDTO from "../dto/app/requestlist.dto";
import ResultListDTO from "../dto/app/resultlist.dto";

type UseListType = <T>(
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  requestList: RequestListDTO,
  dependencies: Array<any>,
  allowFetch?: boolean
) => [boolean, T[] | null, number, number];
var isFetching = false;
const useList: UseListType = <T,>(
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  requestList: RequestListDTO,
  dependencies: Array<any>,
  allowFetch = false
) => {
  const [objects, setObjects] = useState<T[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(-1);
  const [totalPages, setTotalPages] = useState<number>(-1);

  const getList = useCallback(() => {
    if(isFetching && !allowFetch) return;
    isFetching = true;
    setLoading(true);
    const reqList = requestList;
    getListFun(handelGetList, {}, reqList);
  }, dependencies);

  const handelGetList = (result: ResultListDTO) => {
    
    if (result.error) return;
    const objects: T[] = (result.objects as T[]) ?? [];
    const total = result.total ?? 0;
    const totalPages = result.totalpages ?? 0;
    setObjects(objects);
    setTotal(total);
    setTotalPages(totalPages);
    isFetching = false;
  };

  useEffect(() => {
    getList();
  }, [getList]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (objects === null) loading = true;
    if (total === -1) loading = true;
    if (totalPages === -1) loading = true;
    setLoading(loading);
  }, [objects, total, totalPages]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  return useMemo(
    () => [loading, objects, total, totalPages],
    [loading, objects, total, totalPages]
  );
};

export { useList };
