import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class UserRoleRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("userroles");
  }
}
