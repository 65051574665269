import { Box, Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { FAQAnswerDto } from "dto/static/faqanswer.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { FAQAnswerService } from "services/static/faqanswer.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { useDetailSpecial } from "hooks/useDetailSpecial";
import { HeaderDetailSpecial } from "components/elements/detail/HeaderDetailSpecial";

const service = new FAQAnswerService();
const SpecialFAQAnswerDetail: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    idLanguage,
    setIdLanguage,
    mainListUrl
  } = useDetailSpecial<FAQAnswerDto>("faqanswer", currentRoute, get, true);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecial
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        idLanguage={idLanguage}
        specialType="faqanswer"
        mainListUrl={mainListUrl}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>
        <Grid>
          <DetailGrid
            object={object}
            fields={FAQAnswerDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialFAQAnswerDetail };
