import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";
import { DetailGridText } from "./DetailGridText";
import { DetailGridHtml } from "./DetailGridHtml";
import React from "react";
import { DetailGridConfig } from "./DetailGridConfig";
import { DetailGridArray } from "./DetailGridArray";
import { DetailGridImage } from "./DetaiGridImage";
import { DetailGridFile } from "./DetailGridFile";
import { DetailGridVideo } from "./DetailGridVideo";

type Props = {
  object?: Idto;
  fields: Array<DetailObject>;
};
const DetailGrid: React.FC<Props> = ({ object, fields }) => {
  if (!object) return null;
  if (!fields.length) return null;

  return (
    <React.Fragment>
      {fields.map((item, index) => processItem(object, item, index))}
    </React.Fragment>
  );
};

const processItem = (
  obj: Idto,
  item: DetailObject,
  index: number
): React.JSX.Element | null => {
  if (!obj) return null;
  if (!item) return null;
  switch (item.typeField) {
    case Types.FIELD_TYPE_TEXT:
      return (
        <DetailGridText key={index} object={obj} item={item} index={index} />
      );
    case Types.FIELD_TYPE_IMAGE:
      return (
        <DetailGridImage key={index} object={obj} item={item} index={index} />
      );
    case Types.FIELD_TYPE_VIDEO:
      return <DetailGridVideo key={index} object={obj} item={item} index={index} />;
    case Types.FIELD_TYPE_HTML:
      return (
        <DetailGridHtml key={index} object={obj} item={item} index={index} />
      );
    case Types.FIELD_TYPE_CONFIG_TEXT:
      return (
        <DetailGridConfig key={index} object={obj} item={item} index={index} />
      );
    case Types.FIELD_TYPE_ARRAY:
      return (
        <DetailGridArray key={index} object={obj} item={item} index={index} />
      );
    case Types.FIELD_TYPE_FILE:
      return (
        <DetailGridFile key={index} object={obj} item={item} index={index} />
      );
    default:
      return null;
  }
};

export { DetailGrid };
