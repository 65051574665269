import React from "react";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { Box, IconButton } from "@mui/material";

import { SpecialTopInfo } from "components/special/SpecialTopInfo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLabel } from "hooks/useLabel";
import { MyBreadcrumbs } from "../breadcrumb/MyBreadcrumbs";
import { NavButton } from "../button/NavButton";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  parentUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  firstLevelParentId: string;
  firstLevelParentType: string;
  showAddBtn?: boolean;
};
const HeaderListSpecialThirdLevel: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  parentUrl,
  parentType,
  parentId,
  specialType,
  firstLevelParentType,
  firstLevelParentId,
  showAddBtn = true,
}) => {
  const { LL } = useLabel();
  const access = RouteTools.checkRoutePermission(
    RouteTools.getPmHash_Add(specialType)
  );
  showAddBtn = showAddBtn && access;
  return (
    <React.Fragment>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbListSpecialThirdLevel(
          firstLevelParentType,
          firstLevelParentId,
          specialType,
          parentType
        )}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: showAddBtn ? "space-between" : "flex-start",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <NavButton
          href={parentUrl}
          children={<ArrowBackIcon />}
          _hstate={{}}
          _mainurl={parentUrl}
          component={IconButton}
          color="primary"
        />

        <SpecialTopInfo
          parentType={parentType}
          parentId={parentId}
          specialtype={specialType}
        />

        {showAddBtn && (
          <NavButton
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={{}}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderListSpecialThirdLevel };
