import React from "react";
import { HeaderListSpecial } from "components/elements/list/HeaderListSpecial";
import { ProductWishlistService } from "services/product/productwishlist.service";
import { IPage } from "interfaces/page.interface";
import { MyTable } from "components/elements/table/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { ProductWishlistDto } from "dto/product/productwishlist.dto";
import { useListAdminSpecial } from "hooks/useListAdminSpecial";
import { useLabel } from "hooks/useLabel";

const service = new ProductWishlistService();

const SpecialProductWishlist: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    rows,
    total,
    totalPage,
    reqList,
    setReqList,
    loading,
    onRowClickCb,
    parentId,
    parentType,
    parentUrl,
  } = useListAdminSpecial<ProductWishlistDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun,
    "productwishlist",
    ProductWishlistDto.defaultGetRequestList
  );
  const { LL } = useLabel();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderListSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        specialType="productwishlist"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
        showAddBtn={false}
      />
      <MyTable
        _columns={ProductWishlistDto.getColumnsSpecial(LL, mainObject)}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        mainObject={mainObject}
        fieldToShowOnDelete="id"
        onRowClickCb={onRowClickCb}
      />
    </React.Fragment>
  );
};

export { SpecialProductWishlist };
