import { GeneralAutocomplete } from "components/elements/autocomplete/GeneralAutocomplete";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { LocationDto } from "dto/static/location.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { LocationService } from "services/static/location.service";


type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: LocationDto | null;
  all?: boolean;
  idcountry?: number | string;
  value?: string | number;
};

const service = new LocationService();
const AutocompleteLocation: React.FC<Props> = ({
  setObjField,
  defaultObject,
  all = true,
  idcountry,
  value
}) => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    if (!req) req = new RequestListDTO();
    if (!req.filters) req.filters = [];
    if (idcountry) {
      req.filters.push(
        RequestFilterDTO.prepareFilter("idcountry", [idcountry.toString()])
      );
    }

    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = LocationDto.parseOption(defaultObject);

  const parseOptions = (data: LocationDto[]) => {
    return LocationDto.parseToSelectOptions(data, all);
  };

  return (
    <GeneralAutocomplete
      field="idlocation"
      label={LL("field_location")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={parseOptions}
      getListFunction={getList}
      dependencies={[idcountry]}
      mainValue={value}
    />
  );
};

export { AutocompleteLocation };
