import React from "react";
import { HeaderListSpecial } from "components/elements/list/HeaderListSpecial";
import { GalleryService } from "services/system/gallery.service";
import { IPage } from "interfaces/page.interface";
import { MyTable } from "components/elements/table/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { GalleryDto } from "dto/system/gallery.dto";
import { useListAdminSpecial } from "hooks/useListAdminSpecial";
import { useLabel } from "hooks/useLabel";

const service = new GalleryService();

const SpecialGallery: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
    onRowClickCb,
    parentId,
    parentType,
    parentUrl,
  } = useListAdminSpecial<GalleryDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun,
    "gallery"
  );
  const { LL } = useLabel();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderListSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        specialType="gallery"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
      />
      <MyTable
        _columns={GalleryDto.getColumnsSpecial(
          LL,
          mainObject,
          setDeleteOneObject
        )}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={GalleryDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="_name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        onRowClickCb={onRowClickCb}
      />
    </React.Fragment>
  );
};

export { SpecialGallery };
