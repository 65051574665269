import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { EmailTemplateDto } from "dto/notification/emailtemplate.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";
import { SelectFromEmail } from "components/notification/fromemail/SelectFromEmail";
import { MyCKEditorWithParams } from "components/elements/form/MyCKEditorWithParams";

type Props = {
  defaultObject: EmailTemplateDto;
  onSubmit: (data: EmailTemplateDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormEmailTemplate: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setLanguage,,vResults] = useForm<EmailTemplateDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "identifier",
      "idfromemail",
      "_message",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <SelectFromEmail
            setObjectField={setObjField}
            value={obj.idfromemail}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="toemail"
            label={LL("toemail")}
            setObjectField={setObjField}
            value={obj.toemail}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="replyto"
            label={LL("replyto")}
            setObjectField={setObjField}
            value={obj.replyto}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="cc"
            label={LL("cc")}
            setObjectField={setObjField}
            value={obj.cc}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="bcc"
            label={LL("bcc")}
            setObjectField={setObjField}
            value={obj.bcc}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_fromname"
            label={LL("_fromname")}
            setObjectField={setObjField}
            value={obj._fromname}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_toname"
            label={LL("_toname")}
            setObjectField={setObjField}
            value={obj._toname}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_subject"
            label={LL("_subject")}
            setObjectField={setObjField}
            value={obj._subject}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditorWithParams
            field="_message"
            setObjectField={setObjField}
            data={obj._message}
            params={obj.parameters}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormEmailTemplate };
