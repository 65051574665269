import { Box, Skeleton } from "@mui/material";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import React, { useCallback, useEffect, useState } from "react";
import { ServerCategoryLevelSelect } from "./ServerCategoryLevelServer";

import ResultListDTO from "dto/app/resultlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export interface HasId extends Idto {
  id?: number | string;
  idparent?: number | string;
}

type Props = {
  setObjectField: (field: string, value: any) => void;
  value: string | undefined;
  getList: (cb?: any, cbParams?: any, req?: RequestListDTO) => void;
  processOptions: (objects: HasId[]) => Array<SelectOptions>;
  field: string;
  label:string
};

export type KeyObject = {
  [key: number]: string | number;
};

const ServerCategoryBlockSelect: React.FC<Props> = ({
  setObjectField,
  value,
  getList,
  field,
  processOptions,
  label
}) => {
  const defaultValue = value ? null : {};
  const [keyList, setKeyList] = useState<KeyObject | null>(defaultValue);
  const [defaultObjects, setDefaultObjects] = useState<HasId[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const addToList = (key: number, value: string | number) => {
    setKeyList({ ...keyList, [key]: value });
  };
  const removeFromList = (key: number) => {
    const newList = { ...keyList };

    for (let k in newList) {
      if (parseInt(k) >= key) {
        delete newList[parseInt(k)];
      }
    }
    setKeyList(newList);
  };

  const getListObjects = useCallback(() => {
    if (!value) return;
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = -1;
    req.filters = [
      RequestFilterDTO.prepareFilter("hpcodes", [value]),
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    req.sortcriteria = [
      RequestSortCriteriaDTO.prepareSortCriteria("level", true),
    ];
    setLoading(true);
    getList(handleGetList, {}, req);
  }, [value]);

  const handleGetList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : [];
    setDefaultObjects(objects);
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!keyList) loading = true;
    setLoading(loading);
  }, [keyList]);

  const processKeyList = useCallback(() => {
    if (!defaultObjects) return;
    if (!defaultObjects.length) return;
    const keyList: KeyObject = {};
    defaultObjects.forEach((object, index) => {
      keyList[index] = object.id ? object.id : "";
    });
    setKeyList(keyList);
  }, [defaultObjects]);

  useEffect(() => {
    getListObjects();
  }, [getListObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  useEffect(() => {
    processKeyList();
  }, [processKeyList]);

  if (loading) return <Skeleton />;
  if (!keyList) return null;
  return (
    <Box>
      <ServerCategoryLevelSelect
        setObjectField={setObjectField}
        getList={getList}
        idParent={"0"}
        level={0}
        list={keyList}
        processOptions={processOptions}
        field={field}
        addToList={addToList}
        removeFromList={removeFromList}
        label={label}
      />
    </Box>
  );
};

export { ServerCategoryBlockSelect };
