import { NotificationRepository } from "repositories/notification/notification.repository";
import { GeneralService } from "services/general.service";

export class NotificationService extends GeneralService<NotificationRepository> {
  constructor() {
    super(new NotificationRepository());
  }

  sendTestEmail = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.sendTestEmail(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
